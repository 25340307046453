.button-wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

button.portfolio-details-btn {
    height: 40px;
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: #0F0F0F;
        border-color: #FB7B34;
    }

    &:disabled {
        border-color: #CCCCCC;
        background-color: #CCCCCC;
        color: #525252;
    }
    &.btn-upload {
        padding: 10.5px 24px;
        border: 1px solid #0F0F0F;
        color: #0F0F0F;
        background-color: #FCFCFC;
    }
}


.portfolio-delete-btn {
    display: flex;
    align-items: center;
    color: #DC180C;
    font-family: 'Aeonik Quant';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    & svg {
        width: 24px;
        height: 24px;
    }

}

.portfolio-export-btn {
    display: flex;
    align-items: center;
    font-family: 'Aeonik Quant';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;
}

.del-modal {
    & .ant-modal-footer {
        display: flex;
        justify-content: space-between;

        & .del-cancel-button {
            width: 45%;
        }

        & .del-ok-button {
            width: 45%;
        }
    }
}

.modal-test {
    margin: 24px 0;

    &-name {
        font-style: italic;
        font-weight: 600;
        color: #718F83;
    }
}