#order-instructions.detail-container.portfolio-container {
    margin-top: 24px;

    &>h2.portfolio-container-title {
        margin-top: 0;
    }

    .transactions-wrapper {
        padding: 24px;
        min-height: calc(100vh - 400px);
    }

    .transactions-select {
        width: 100%;
        max-width: 480px;
    }

    & .loading-wrapper.gear {
        height: 380px;
    }

    & .transaction-tabs-wrapper {
        margin-top: 10px;

        & button.ant-btn-active {
            border-bottom: 1px solid #d6d6d6;
            background-color: transparent;
            border-left: none;
            border-top: none;
            border-right: none;
            box-shadow: none;
            transition: box-shadow 0.3s ease;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            span {
                color: #305572;
            }

        }
    }
    & .transaction-table-wrapper {
        width: 100%;
    }
    & .transaction-total-row {
        background-color: #30557221;
    }
}