.details-header {
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  letter-spacing: 0.2px;
  margin-top: 50px;
  margin-bottom: 80px;
}

.detail-container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 480px;
  padding: 20px;
  margin-bottom: 14px;
}

.detail-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.input-text {
  margin-left: 10px;
}

.account-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #626363;
  margin: 14px 0;
}

.btn-container {
  width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  margin-top: 46px;
}

.menu-title {
  width: 100%;
  text-align: center;
  margin: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #626363;
  margin-top: 4px;
}

.menu-wrapper {
  box-sizing: border-box;
  padding: 0;
  width: 81px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.radio-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #626363;
}

.radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.myDetails-addbutton {
  margin-top: 6px;
  height: 20px;
  color: #305572;
}

.myDetails-phone {
  margin-bottom: 0;
  width: 100%;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #799AA9;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #799AA9;
  background-color: #799AA9;
}

.my-details-checkbox.experience-background-checkbox .ant-checkbox-inner {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #799AA9;
  border-radius: 3px;
}

.my-details-checkbox.experience-background-checkbox>.ant-checkbox-checked>.ant-checkbox-inner::after {
  left: 28.6%;
  width: 7.2px;
  height: 7.5px;
}

.my-detail-checkbox-wrapper {
  margin-bottom: 0;
}

.ekonomik-subtitle.representative-top {
  margin-top: 24px;
}

.ekonomik-subtitle>span.representative-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #626363;
}

.ekonomik-subtitle.representative-top .ant-input-number::after {
  content: "%";
  position: absolute;
  top: 0px;
  right: 22px;
}

.ekonomik-subtitle.representative-top .ant-form-item {
  margin-bottom: 0;
}

.ekonomik-lable.representative-lable+.ant-form-item-has-success {
  margin-bottom: 16px;
}

.representative-del-button {
  margin-bottom: 12px;
}

.ekonomik-lable.representative-lable+.ant-slider {
  margin: 16px 6px 0px;
}

.upload-button {
  margin-left: auto;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 175px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: 5px 5px 5px #799AA9;
  }
}

.upload-list {
  margin: 10px 0;
  padding: 6px;
  border: 1px solid #c5c5c5;
  background-color: #f1f1f163;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  &-delete {
    cursor: pointer;
  }
}