.portfolio-counter {
    color: var(--time-time-100, #423A38);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.client.portfolio-counter-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.summary-data-wrapper {
    display: flex;
    flex-wrap: nowrap;
    border-top: 1px solid var(--border, #2B2726);
    overflow-y: hidden;

}

.portfolio-side-wrap {
    display: flex;
    flex-basis: 72.5%;
    min-height: calc(100vh - 285px);
    border-right: 1px solid var(--border, #2B2726);
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
}

.class-side-wrap {
    flex-basis: 27.5%;
}

.footer-holdings-title {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-align: left;
    color: var(--fc-third-color);

}