.ant-select.ant-select-outlined.custom-select {
    width: 100%;
    max-width: 360px;
    height: 48px;
    border-radius: 24px;
    font-family: 'Aeonik Quant';
    font-size: 16px !important;
    font-weight: 400;
    line-height: 18.56px;
    color: #0F0F0F;
    border: 1px solid #d9d9d9;

    & .ant-select-selection-wrap {
        display: contents;
    }

    & .ant-select-selector:hover {
        cursor: pointer;
    }

    &>.ant-select-selector {
        border: none;
    }

    & .ant-select-selection-placeholder {
        padding-left: 12px;
    }

    &>.ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        border-color: transparent !important;
        background: none;

        & .ant-select-selection-item {
            font-size: 16px !important;
            border-radius: 12px;
            padding-inline-start: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    & .ant-select-arrow {
        inset-inline-end: 3px;
    }

    &.ant-select-disabled {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: not-allowed;

        & .ant-select-selector {}
    }

}

.ant-select-dropdown {
    border: none !important;
    background-color: transparent !important;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    overflow: visible;
}

.custom-dropmenu-wrapper {
    width: 100%;
    max-width: 360px;
    background-color: transparent !important;

    & .ant-select-item.ant-select-item-option {
        background-color: #FCFCFC;
        border-radius: 24px;
        padding: 12px 24px;
        font-family: 'Aeonik Quant';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }


    .ant-select-item-option-active {
        border: 1px solid #FB7B34
    }
}

.ant-select-selection-search input.ant-select-selection-search-input {
    padding-left: 12px !important;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
}

#mapping-instrument {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    & .instrument-select {
        width: 360px;
    }

    & .maping-helper {
        width: 100%;
        text-align: center;
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        color: #262626;
        margin-top: 0;

        &+p {
            font-family: Aeonik Quant;
            font-size: 16px;
            font-weight: 500;
            line-height: 18.56px;
            text-align: center;
            margin-bottom: 24px;

        }
    }
}

.mapping-modal {
    &-header {
        font-family: Aeonik Quant;
        font-size: 24px;
        font-weight: 700;
        line-height: 27.84px;
        text-align: center;
        color: #232323;
        margin-top: 56px;
    }

    &-text {
        margin-top: 56px;
        margin-bottom: 24px;
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        text-align: center;
        color: #262626;
    }
}