.footer {
  background-color: white !important;
  width: 100%;
  min-height: 80px;
  border-top: 1px solid grey;
  padding: 10px 24px !important;
  display: flex;
  margin: 0;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 15;

  .vertical-line {
    height: 100%;
    left: 76px;
    border-right: 1px solid grey;
    position: absolute;
    bottom: 0;
  }
}

.logotype-footer {
  margin: 0 20px 0 -4px;
  padding: 5px;
  background-color: white;
  width: fit-content;
  position: relative;
  left: -10px;
  z-index: 20;

  img {
    height: auto;
    width: 4rem;
  }
}

.footer-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Aeonik Quant';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
}

.footer-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;

  &>p {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &>a {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;
  }
}

.footer-col.center {
  display: flex;
  height: fit-content;
  gap: 4px;
}

.footer-col.button {
  width: 200px;
  border: 1px solid black;
  padding: 20px;
  position: relative;

  .button-arrow {
    color: black;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.footer-col-center {
  color: #626363;
  padding: 0 25px;
}

.footer-col-left {
  margin-right: 25px;
}

.footer-col-right {
  margin-left: 25px;
}

.footer-became {
  width: 377px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #bcc2c2;
  box-sizing: border-box;
  color: #626363 !important;
  border-radius: 20px;
  padding: 8px 25px;
}