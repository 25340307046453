.instrument-wrapper {
  width: 100%;

  .tabs-position {
    z-index: 20;
    background-color: white;
    width: 100vw;
    position: fixed;
    top: 87px;
    left: 0;

    .tabs-row {
      background-color: white;
      padding: 10px 20px;
      border-bottom: 1px solid black;

      a+a {
        margin-left: 30px;
      }

      a {
        color: black;
      }

      .active-link {
        color: #217fe4;
      }
    }
  }
}

.instrument-inner-wrapper {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F6F7F9;
}

.instrument-container {
  margin-top: 24px;
}

.instrument-del-modal {
  & .ant-modal-content {

    padding: 72px 20px;
  }
}

.del-modal-header {
  width: 100%;
  font-family: Aeonik Quant;
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: center;
  color: #232323;
  margin-bottom: 56px;
}

.del-modal-text {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: center;
  color: #262626;
  margin: 0;
  margin-bottom: 28px;

}

.del-modal-name {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  text-align: center;
  margin: 0;
}

.del-cancel-button {
  width: 115px;
  height: 40px;
  padding: 10px 32px;
  border-radius: 24px;
  border: 1px solid #0F0F0F;
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #0F0F0F;

}

.del-ok-button {
  width: 112px;
  height: 40px;
  padding: 10px 32px;
  border-radius: 24px;
  background-color: #DC180C;
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #FCFCFC;

}

.instrument-header-title {
  margin: 0;
  font-family: Aeonik Quant;
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  color: #0F0F0F;
}

.ant-table-cell {
  &.name {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    color: #0F0F0F;
  }

  &.isin {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    color: #525252;
  }

  &.share-price {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    color: #525252;
  }

  &.diversification {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    & .diver-block {
      display: inline-block;
      min-width: 50px;
      height: 24px;
      padding: 2px 8px;
      border-radius: 8px;

      &.none {
        color: #6367C3;
        background-color: #6367C31F;
      }

      &.medi {
        color: #E59A00;
        background-color: #FFAB001F;
      }

      &.hight {
        color: #139E19;
        background-color: #139E191F;
      }

    }

  }

  &.fee {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #6367C3;

    &>span {
      display: inline-block;
      min-width: 58px;
      height: 24px;
      padding: 2px 8px;
      border-radius: 8px;
      background-color: #6367C31F;
      white-space: nowrap;
    }
  }

  &.asset-class {

    & .holdings-assetclass-cell-asset {
      font-family: Aeonik Quant;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.24px;
      color: #0F0F0F;
    }

    & .holdings-assetclass-cell-category {
      font-family: Aeonik Quant;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.24px;
      color: #808080;
    }
  }
}

.currency-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

button.portfolio-details-btn.btn-create {
  height: 40px;
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #0F0F0F;
  background-color: #FB7B34;

  &:disabled {
    color: #525252;
    background-color: #FAE5D8;
  }
}

//
.instrument-inner-wrapper,
.table-inner-wrapper {

  .ant-table-thead>tr>th {
    background: #F7F7F8;
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;

    color: #525252;
    border-right: none !important;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: none !important;
  }

  .ant-pagination-item-active {
    border-color: #0F0F0F;
    background-color: #0F0F0F;
    border-radius: 6px;

    a {
      color: #FB7B34;
      font-family: Aeonik Quant;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.table-header-position-center>.ant-table-column-sorters {
  justify-content: center;
}

.table-header-position-right>.ant-table-column-sorters {
  justify-content: flex-end;
}