g.label-chart {
    &>text tspan {
        display: block;

        &.highcharts-text-outline>tspan>.highcharts-br+tspan {
            min-width: 100%;
        }
    }
}
// .highchart-pie {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     overflow: hidden;
//     width: 270px;
//     height: 264.5px;
//     margin: 0 auto;
// }