.experience-background {
    &-container.detail-container {
        width: 100%;
        max-width: 1200px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    &-grid {
        display: grid;
        grid-template-columns: minmax(180px, 107px) minmax(118px, 241px) minmax(132px, 193px) minmax(127px, 250px) minmax(259px, 259px);
        margin-top: 16px;
        row-gap: 16px;
    }

    &-header {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #000000;

        &>.w-118 {
            width: 118px;
        }

        &.pl-62 {
            padding-left: 62px;
        }

        &.center {
            justify-content: center;
        }
    }

    &-cell {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #626363;

        &.pl-62 {
            padding-left: 62px;
        }

        &.center {
            justify-content: center;
        }
    }

    &-input {
        width: 118px;
        height: 24px;
        background: #FFFFFF;
        border: 1px solid #DCDDDD;
        border-radius: 5px;
        padding: 4px 2px 4px 10px;
    }

    &-select.ant-select {
        min-width: 132px;
        height: 24px;
        background: #FFFFFF;
        border: 1px solid #DCDDDD;
        border-radius: 5px;
    }

    &-select.ant-select>.ant-select-selector {
        min-width: 132px;
        max-width: 190px;
        height: 24px;
        background: #FFFFFF;
        border: 1px solid #DCDDDD;
        border-radius: 5px;
        padding-right: 25px;

        & .ant-select-selection-placeholder,
        & .ant-select-selection-item {
            display: inline-flex;
            align-items: center;
        }
    }

    &-checkbox {
        & .ant-checkbox-inner {
            box-sizing: border-box;
            width: 24px;
            height: 24px;
            border: 1px solid #799AA9;
            border-radius: 3px;
        }

        &>.ant-checkbox {
            top: 0;
        }

    }

    &-checkbox>.ant-checkbox-checked>.ant-checkbox-inner {
        background-color: #799AA9;

        &::after {
            left: 30%;
            width: 10px;
            height: 10.5px;
        }
    }

}