.header {
  display: flex;
  justify-content: space-between;
//   flex-direction: column;
  margin-bottom: 40px;
}

.ekonomik-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.ekonomik-subtitle {
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
  }
}


