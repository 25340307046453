.report-wrapper {
  width: 100%;

  &.tabs-position {
    z-index: 20;
    background-color: white;
    width: 100vw;
    position: fixed;
    top: 81px;
    left: 0;
  }

  .report-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0F0F0F;

  padding: 10px 32px;
  border: 1px solid #0F0F0F;
  border-radius: 24px;

  &:hover {
    color: #0F0F0F !important;
  }
}

.client-profile-wrapper {
  position: relative;

  & span.ant-table-column-title {
    display: inline-block;
    flex: none;
  }

  & .ant-table-column-sorters {
    justify-content: flex-start;
  }
}

.report-done-wrapper {
  width: 360px;
  height: 127px;
  border-radius: 13px;
  border: 2px;
  border: 2px solid #FB7B34;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  top: 90px;
  right: 40px;
  background-color: white;
  z-index: 1700;
}

.close-cross-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 7px;
}

.report-done-loader {
  min-width: 96px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.report-done-data {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.report-done-data-header {
  width: 100%;
  margin: 0;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #FB7B34;

}

.report-done-data-name {
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0F0F0F;
  margin: 8px 0;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-done-data-btn {
  border: 1px solid #0F0F0F;
  color: #0F0F0F;
  background-color: #FCFCFC;
  width: 138px;
  height: 40px;
  font-family: "Aeonik Quant";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #0F0F0F;
  padding: 11px 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}