body.dark-theme {
    --secondary-btn-bcg: #FCFCFC;
    --secondary-btn-color: #0F0F0F;
    --secondary-btn-border: 1px solid #0F0F0F;
    --secondary-btn-border-hover: 1px solid #FB7B34;
}

body.light-theme {
    --secondary-btn-bcg: #FCFCFC;
    --secondary-btn-color: #0F0F0F;
    --secondary-btn-border: 1px solid #0F0F0F;
    --secondary-btn-border-hover: 1px solid #FB7B34;

}

.ant-btn-default.ant-btn-color-default.ant-btn-variant-outlined.secondary-btn {
    min-width: 113px;
    height: 40px;
    padding: 11px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-btn-bcg);
    color: var(--secondary-btn-color);
    border: var(--secondary-btn-border);
    border-radius: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;

    &:hover {
        border: var(--secondary-btn-border-hover);

    }
}

.header-row {
    width: 100%;
    max-width: 1128px;
    gap: 20px;
}

.account-checkbox.checkbox-block>.ant-space>.ant-space-item {
    margin-top: 0;
}