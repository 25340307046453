.risk-profile {
  &-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #000000;
  }

  &-lable {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    margin: 12px 0 0 0;
  }

  &-select.ant-select {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
  }

  &-select .ant-select-selector {
    height: 40px !important;
    align-items: center;
  }
}