div.risk-container {
  width: 100%;
  max-width: 780px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.risk-paragraph {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #626363;
}

.risk-table {
  display: grid;
  grid-template-columns: minmax(86px, 115px) minmax(30px, 30px) minmax(112px, 203px) minmax(59px, 126px) minmax(68px, 134px) minmax(136px, 200px);

  &-header {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #000000;
    border-bottom: 1px solid #DCDDDD;
    padding: 6px;

    &-annual {
      width: 66px;
    }

    &-exempel {
      width: 112px;
    }

    &-sri {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
    }

    &-center {
      justify-content: center;
    }
  }

  &-cell {
    height: 46px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
    padding: 0 6px;
    border-bottom: 1px solid #DCDDDD;

    &-exempel {
      width: 112px;
    }

    &-center {
      justify-content: center;
    }

    &-sri {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.2px;
      color: #FFFFFF;
      padding: 0 6px;

      &-1 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #78B142;
        border-bottom: #78B142;
        border-top: #78B142;
      }

      &-2 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #6DAA49;
        border-bottom: #6DAA49;
        border-top: #6DAA49;
      }

      &-3 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #63A65F;
        border-bottom: #63A65F;
        border-top: #63A65F;
      }

      &-4 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #5EA272;
        border-bottom: #5EA272;
        border-top: #5EA272;
      }

      &-5 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #529E7F;
        border-bottom: #529E7F;
        border-top: #529E7F;
      }

      &-6 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #4B9B8C;
        border-bottom: #4B9B8C;
        border-top: #4B9B8C;
      }

      &-7 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        background: #4497A1;
        border-bottom: #4497A1;
        border-top: #4497A1;
      }
    }
  }
}

.risk-table-cell-sri+.risk-table-cell-center {
  padding-left: 35px;
}

.risk-table-header-sri+.risk-table-header-center {
  padding-left: 35px;
}

.risk-info {
  margin-top: 16px;

  & a {
    display: flex;
    column-gap: 7px;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #3F969F;
  }
}

.risk-action {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 17px;
  margin-top: 30px;
}

.risk-check-icon {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.risk-check-button {
  width: 33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & svg {
    border: unset;
  }

  & .ant-space-item {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #718F83;
  }
}

.risk-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 25px;
  background: #799AA9;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}