.detail-container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 480px;
  padding: 20px;
  margin-bottom: 14px;
}

.detail-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.purpose-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;

  &>label {
    flex-basis: 48%;
    margin-right: 0;
  }
}

.purpose {
  &-title {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    margin-bottom: 0;
    margin-top: 12px;
  }

  &-tags-addbutton {
    display: inline-block;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #718F83;
    margin-top: 12px;

    &:hover {
      color: #41534c;
    }
  }

  &-tags-menu {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
  }

  &-checkbox-group {
    margin-top: 10px;
  }

  &-checkbox span {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
  }

  &-tags-wrapper {
    display: flex;
    padding-top: 3px;
    gap: 15px;
    min-height: 24px;
  }

  &-tags {
    margin: 0;
    padding: 3px 5px;
    border-radius: 6px;
    background-color: rgba(209, 238, 226, 0.185);
    cursor: pointer;
  }
}

label.ant-checkbox-group-item:last-child.ant-checkbox-group-item:nth-child(odd) {
  margin-right: auto;
}