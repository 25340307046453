.custom-steps-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-steps-item-wrap {
  width: 270px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  background-color: #FCFCFC;
  padding: 16px 24px;
  border: 1px solid #0F0F0F;

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #0F0F0F;

  &.checked {
    border: 1px solid #FB7B34;
  }

  &.current {
    color: #FCFCFC;
    background-color: #0F0F0F;
  }

}

.custom-steps-item-dot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FB7B34;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;

  color: #0F0F0F;

}

.custom-steps-didider {
  display: inline-block;
  width: 16px;
  border: 1px solid #000000;
}