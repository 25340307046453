body.dark-theme {
    --checkbox-border: #F2EEED;
}

body.light-theme {
    --checkbox-border: #0F0F0F;
}

.custom-checkbox {
    & .ant-checkbox {
        display: inline-block;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        border: 1px solid var(--checkbox-border);
        border-radius: 8px;

        &.ant-checkbox-checked {

            &.ant-checkbox-wrapper-disabled>.ant-checkbox-inner {
                background-color: #FAE5D8 !important;

            }

            & .ant-checkbox-inner::after {
                top: 0;
                inset-inline-start: 0;
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url('../../assets/checkbox-icon.svg') no-repeat center center;
                background-size: contain;
                transform: scale(0.775)
            }
        }

        &>input.ant-checkbox-input[type="checkbox"],
        &>.ant-checkbox-inner {
            border: none;
            width: 22px;
            height: 100%;
            background-color: transparent;
            border-radius: 8px;
        }

        & .ant-checkbox-inner:after {
            border-color: transparent;
            inset-inline-start: 33%;
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        border-color: var(--checkbox-border);
        background-color: #FB7B34;
    }

    .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
        border-color: var(--checkbox-border);
        background-color: #FAE5D8;
    }

}