.bubble-toltip-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
    z-index: 9999;
}

.bubble-toltip-amount {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #FB7B34;
    margin: 8px 0;
    z-index: 9999;
}

.bubble-toltip-date {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #808080;
    text-align: right;
    margin: 0;
    z-index: 9999;
}