body.dark-theme {
    --divider-border-color: #2B2726;
    --btn-font-color: #656464;
    --user-box-border-color: #2B2726;
    --user-box-bcg-color: #5B5958;
    --bottom-btn-color: #F2EEED;
    --toggler-color: #ffffff;
    --name-font-color: #CCCCCC;
    --role-font-color: #827F7F;
}

body.light-theme {
    --divider-border-color: #E1E1E9;
    --btn-font-color: #0F0F0F;
    --user-box-border-color: #000000;
    --user-box-bcg-color: #FCFCFC;
    --bottom-btn-color: #3C4043;
    --toggler-color: #000000;
    --name-font-color: #0F0F0F;
    --role-font-color: #3C4043;
}


.logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 24px 16px 32px;
    max-height: 71px;
}

.logotype {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 12;

    img {
        height: auto;
        width: 146px;
    }
}

.client-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 16px 8px;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);

    & .logout-link {
        width: 100%;
        color: #DC180C;
        padding: 12px 56px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
    }
}

.client-button-wrapper {
    padding-top: 10px;
    max-height: 48vh;
    min-height: 48vh;
    overflow-y: auto;
    background-color: #FCFCFC;
    transition: max-height 1s;

    &.hidden {
        max-height: 0;
        min-height: 0;
        padding-top: 0;
        overflow: hidden;
    }
}

.client-nav-wrapper {
    padding: 10px 5px 0 0;
    max-height: 62vh;
    overflow-y: auto;

    &.hidden {
        max-height: 0;
        min-height: 0;
        padding-top: 0;
        overflow: hidden;
    }

    &>.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
        padding: 0;
        background-color: transparent;
        border-inline-end: none !important;

        & .ant-menu-submenu-title {
            padding-left: 14px;
            background-color: #F6F7F9;
        }

        & .ant-menu.ant-menu-sub.ant-menu-inline {
            background: none !important;

            &>.ant-menu-item.ant-menu-item-only-child {
                height: 48px;
            }
        }

        & span.ant-menu-title-content {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: #0F0F0F;
        }
    }

    .ant-menu-submenu-title {
        margin: 0 !important;
    }

}


.client-box {

    &-title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &.user-box {
            background: #F6F7F9;
            border-radius: 8px;

            &.selected-user {
                color: #0958d9;
                border-color: #0958d9;
                background: #ffffff;
            }
        }
    }

    &-text {
        &-wraper {
            box-sizing: border-box;
            width: 131.42px;
            text-align: left;
            padding-left: 10px;

            &>span.ant-typography {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }

        }
    }
}

.add-client-btn {
    width: calc(100% - 16px);
    height: 40px;
    background: #FCFCFC;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #0F0F0F;
    border-radius: 24px;
}

.client-box-title {
    width: 226px;
    margin-top: 16px;
    border: 1px solid #000000;
    border-radius: 24px;
    background-color: #FCFCFC;
    padding: 12px 16px;

    &.uncollapsed {
        border: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(.uncollapsed) {
        display: flex;
        align-items: center;
    }

    &.desabled {
        cursor: no-drop;
    }
}



.ant-btn.client-list-item {
    display: block;
    width: 100%;
    text-align: left;
    height: 44px;
    padding-left: 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &.selected-user {
        background: #ffffff;

        &>span {
            color: #0958d9;

        }
    }

}

.ant-btn.client-menu-btn {
    display: block;
    width: calc(100% - 16px);
    height: 44px;
    padding-left: 56px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;

    &.selected {
        border-radius: 24px;
        background: #FB7B34;
    }

}

li.ant-menu-submenu>.ant-menu.ant-menu-sub.ant-menu-inline {
    & .ant-menu-item {
        width: calc(100% - 16px);
        height: 44px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #0F0F0F;
        margin: 0;

        &>.ant-menu-title-content {
            font-size: 14px;

        }

        &.ant-menu-item-selected {
            border-radius: 24px;
            background: #FB7B34;

            &>.ant-menu-title-content {
                font-size: 16px;

            }
        }
    }

}

div.ant-menu-submenu-title {
    &+ul.ant-menu.ant-menu-sub.ant-menu-inline {
        margin-top: 10px;
    }
}

.button-title-wrap {
    display: flex;
    flex-wrap: wrap;

    &>p {
        margin: 0;
        width: 100%;
    }
}

// ------------------------------------------

#client {
    &.sider-style {
        background-color: var(--bg-primary-color);
        text-align: center;
        color: #fff;
        border-right: 1px solid var(--divider-border-color);
    }

    & .client-menu-divider {
        height: 1px;
        background-color: var(--divider-border-color);
        margin-top: 0;
        width: 100%;
    }

    & .client-menu-btn {
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        align-items: center;
        width: calc(100% - 16px);
        height: 44px;
        padding-left: 12px;

        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        color: var(--btn-font-color);

        &.selected {
            border-radius: 8px;
            background: none;
            color: var(--fc-secondary);

        }

        &:not(.selected) svg>circle {
            fill: var(--bg-primary-color)
        }
    }

    & .client-icon-btn {
        margin-bottom: 16px;
    }

    & .user-box {
        width: 226px;
        min-height: 64px;
        margin-top: 16px;
        border: 1px solid var(--user-box-border-color);
        border-radius: 24px;
        background-color: var(--user-box-bcg-color);
        padding: 12px 16px;
        transition: height 1s;

        &.uncollapsed {
            border: none;
        }

        &:not(.uncollapsed) {
            display: flex;
            align-items: center;
        }

        &-title {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }
    }

    & .client-box-text {
        font-size: 13px;
        font-weight: 500;
        line-height: 17.76px;
        letter-spacing: 0.02em;
        text-align: left;
        width: fit-content;
        margin: 0;
        padding: 0;
        color: var(--bottom-btn-color);

        &-wraper {
            box-sizing: border-box;
            width: 131.42px;
            text-align: left;
            padding-left: 10px;

            &>span.ant-typography {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                color: var(--bottom-btn-color);
            }

        }
    }

    .toggle-btn {
        & svg>path {
            fill: var(--toggler-color);
        }
    }

    .menu-btn-wrap {
        display: flex;
        align-items: center;
        padding-right: 22px;
        justify-content: space-between;
        cursor: pointer;

        & .client-menu-btn {
            &+svg>path {
                fill: var(--btn-font-color);

            }

            &.selected {
                &+svg>path {
                    fill: var(--fc-secondary);
                }
            }

        }
    }
}

.client-bottom-box {
    display: flex;
    height: 72px;
    padding: 16px 24px 16px 8px;
    justify-content: space-between;
    align-items: center;
}

.client-text-name {
    color: var(--name-font-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    margin-bottom: 5px;
}

.client-text-role {
    color: var(--role-font-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin: 0;
}

.client-logout-link {
    padding-left: 16px;
    cursor: pointer;

    &:hover {
        & svg>path {
            fill: var(--name-font-color);
        }
    }
}

.account-select-wrap {
    background: var(--bg-secondary-color, #1B1918);
    padding: 16px 0;
    padding-left: 32px;
    padding-right: 25px;

    &.collapsed {
        padding: 16px 0;
        padding-left: 16px;
        padding-right: 10px;

    }
}

.account-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 10%;
    color: var(--fc-secondary);
    text-transform: uppercase;
    text-align: left;
}

.account-select-btn {
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;

    &>span {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -2%;
        color: #CCCCCC;
    }

    & svg {
        transition: transform 0.3s ease-in-out;
    }


}

.uncollapsed>.account-select-btn svg {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

button {

    &.account-item {
        width: 100%;
        // padding: 6px 0;
        justify-content: flex-start;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -2%;
        color: #CCCCCC;
    }

    &.account-item:disabled {
        color: #ccccccd5;
    }

    &.account-item:hover>span {
        color: #ccccccd5;
    }
}