.sign-up-success {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 40px !important;

    p {
        text-align: center;
    }
}

.confirm-success-wrapper {
    width: 360px;
}

.confirm-success-title {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: center;
    color: var(--fc-secondary);
}

.confirm-success-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    margin-top: 32px;
    color: var(--login-placeholder-fc);
}

p.confirm-success-link {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--fc-secondary);

    & svg>g>path {
        fill: var(--login-forgot-arrow, #0F0F0F)
    }
}