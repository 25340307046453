.sign-up-row {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    // background-image: url('../Verbier_BackSide.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F7F9;
}

.sign-up-container {
    width: 100%;
    max-width: 550px;
    padding: 20px 40px !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-sizing: border-box;

    .sign-up-input-block {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 10px;
    }

    a {
        color: dodgerblue;
        display: block;
        text-decoration: underline;
        margin-bottom: 5px;
    }

    ul {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .agreement-block {
        display: flex;
        align-items: center;
        flex-direction: row !important;

        input {
            margin-right: 10px;
        }
    }
}

.sign-up-wrapper {
    display: flex;
    flex-direction: column;

    label::before {
        content: none !important;
    }
}

.checkbox-show-password-block {
    width: fit-content;
    margin-bottom: 15px;
}

.radio-input-block {
    display: flex !important;
    justify-content: space-around;
    padding: 0 20px;
    margin-bottom: 30px !important;
}

.ant-radio-wrapper-in-form-item.custom-radio-btn.client-signup {
    align-items: center
}

.sign-up-lable-radio {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: var(--login-label-fc);
}

div.ant-form-item.radio-acc-type {
    margin-bottom: 0;
}

#sign-up-form .sign-up-note {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: var(--login-label-fc);
    margin: 0;
}