.send-email-wrapper {
  background-image: url('../Verbier_BackSide.jpg');
  background-position: 0 -200px;
  background-repeat: no-repeat;
  background-size: 100%;
  height: calc(100vh - 91px - 80px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.send-email-block {
  background-color: white !important;
  width: 500px;
  padding: 40px !important;

  .send-email-input-block {
    display: flex;
    flex-direction: column;
    position: relative;
    label::before {
      content: none !important;
    }
  }
}
