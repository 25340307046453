.reset-container {
    width: 100%;
    padding: 20px 40px !important;
    display: flex;
    flex-direction: column;
    background-color: white !important;
    h2 {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    label {
        font-size: 14px;
        margin-bottom: 5px;
    }
    input {
        outline-color: dodgerblue;
        margin-bottom: 25px;
        padding: 5px 10px;
        font-size: 16px;
    }
    .checkbox-show-password-container {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        input {
            margin: 0 10px 0 0;
        }
        label {
            margin: 0;
        }
    }
}
.reset-input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    input {
        margin-bottom: 0;
    }
    label::before {
        content: none !important;
    }
}

.reset-pass-form {
    width: 360px;
    padding: 0;
}