body.dark-theme {
    --phon-border-color: #2B2726;
    --bg-color: #1B1918;
    --arrow-color: #FCFCFC;
}

body.light-theme {
    --phon-border-color: transparent;
    --bg-color: #FCFCFC;
    --arrow-color: #0F0F0F;
}

.phone-number-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 360px;
    height: 48px;
    border: 1px solid var(--border-color);
    border-radius: 24px;
    background-color: transparent;

    & .ant-input {
        border: none;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        background-color: transparent;
        color: var(--fc-secondary);

    }

    & .select-phonecode {
        box-sizing: border-box;
        max-width: 130px;
        padding-left: 0px;
        height: 46px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border-color: transparent;
        background-color: var(--bg-color);

        &>div.ant-select-selector {
            border: none !important;
            background-color: transparent;
            box-shadow: none !important;

            & input {
                border: none !important;
                background-color: transparent;

            }
        }

    }

    & .ant-select-arrow svg {
        fill: var(--arrow-color);
    }

    & .ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector,
    & .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
        background-color: transparent;
    }
}

.ant-select-dropdown {

    & .ant-select-item.ant-select-item-option {
        background-color: var(--bg-color);
        border: 1px solid var(--phon-border-color);
        border-radius: 24px;
        padding: 12px 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }


    & .ant-select-item-option-active {
        border: 1px solid #FB7B34
    }
}

.flag-image {
    width: 32px;
    height: 24px;
    margin-right: 10px;
    object-fit: cover;
}

.code-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: left;
    color: var(--fc-secondary);

}

.county-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding-right: 8px;
    color: var(--fc-secondary);
}

.code-label-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 14px;
}

.ant-select-item-option-content {
    display: flex;
    align-items: center;

}