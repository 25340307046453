.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 32px;
}

.portfolio-header-title {
    font-family: Aeonik Quant;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;

}

.header-title {
    font-family: 'Aeonik Quant';
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    margin: 0;
    padding: 0;
}

.bias-header-title,
.constraints-header-title,
.fixation-header-title {
    padding: 12px 0 32px;
}

#pdf-part-2.detail-container.portfolio-container,
#pdf-part-3.detail-container.portfolio-container,
#pdf-part-4.detail-container.portfolio-container,
#pdf-part-5.detail-container.portfolio-container,
#pdf-part-6.detail-container.portfolio-container,
#pdf-part-7.detail-container.portfolio-container,
#pdf-part-8.detail-container.portfolio-container,
#pdf-part-9.detail-container.portfolio-container,
#order-instructions.detail-container.portfolio-container,
#financial-planning-deposit.detail-container.portfolio-container,
#financial-planning-withdrawal.detail-container.portfolio-container,
#financial-planning.detail-container.portfolio-container,
#portfolio-holdings-listed.detail-container.portfolio-container,
#portfolio-holdings-unlisted.detail-container.portfolio-container,
#portfolio-info-cont.detail-container.portfolio-inf-container,
#portfolio-bias-cont.detail-container.portfolio-bias-container,
#portfolio-constraints-cont.detail-container.portfolio-constraints-container,
#portfolio-fixed-cont.detail-container.portfolio-fixed-container,
#portfolio-optim-settings.detail-container.portfolio-optim-settings-container,
#portfolio-holdings-cont.detail-container.portfolio-container {
    box-sizing: border-box;
    background: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #dde9ea;
    border-top: none;
    width: 100%;
    min-width: 1128px;
    padding: 48px 24px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.0784313725), 0px -6px 12px rgba(0, 0, 0, 0.0784313725);

    & .portfolio-container-title {
        padding-top: 16px;
    }

    & .ant-btn-active {
        background-color: #ffffffa1;
        border-left: 1px solid #d6d6d6;
        border-top: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-bottom: none;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        span {
            color: #305572;
        }

    }

    & .ant-btn.btn-edit-edit {
        width: 216px;
        height: 44px;
        box-sizing: border-box;
        padding: 11px 22px;
        margin-right: 0;
        border-radius: 4px;
        border: 1px solid #1A3B5B;
        background-color: #1A3B5B;

        &:hover {
            background-color: #1a3b5bc2;

        }

        &>span {
            color: #ffffff;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;

        }
    }

    & .btn-wrapper {
        position: absolute;
        top: 20px;
        right: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        gap: 24px;
    }

    & .btn-edit-cancel {
        width: 96px;
        height: 44px;
        padding: 11px, 22px;
        border-radius: 4px;
        border: 1px solid #1A3B5B;
    }

    &>.header-wrapper {
        margin-bottom: 24px;
        position: relative;

        &>button,
        &>.btn-wrapper {
            position: absolute;
            right: 0;
            top: -50px;
        }
    }


    .ant-input-number-input-wrap {
        width: fit-content;
        display: inline-block;
    }

    .percentage {
        & .ant-input-number-input-wrap {
            display: inline-block;
            width: fit-content;

            &::after {
                content: "%";
            }

            &>.ant-input-number-input {
                max-width: 57px;
            }
        }

        &.blank {
            & .ant-input-number-input-wrap>.ant-input-number-input {
                width: 43px;
                padding-right: 0;
            }
        }

        &.one-dig {
            & .ant-input-number-input-wrap>.ant-input-number-input {
                width: 43px;
                padding-right: 0;
            }
        }

        &.two-dig {
            & .ant-input-number-input-wrap>.ant-input-number-input {
                width: 50px;
                padding-right: 0;
            }
        }

        &.three-dig {
            & .ant-input-number-input-wrap>.ant-input-number-input {
                width: 57px;
                padding-right: 0;
            }
        }
    }

    & .fixed-select {
        width: 100%;
    }

    & .add-deposit {
        width: 180px;
        height: 25px;
        font-size: 12px;
        margin-top: 20px;
    }
}

#portfolio-info {
    padding: 0 24px 5px 24px;
    max-width: 600px;
}

.portfolio-constraints,
.portfolio-fixation {
    padding: 0 24px;
}



.table-cell-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #DEDEDE
}

.constraints-table-header,
.fixation-table-header {
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: left;
    color: #525252;

    // padding: 15.5px 10px;
}

.constraints-table-cell {
    padding: 11px 24px;
    border-bottom: 1px solid #DEDEDE
}

.fixed-table-cell {
    padding: 11px 24px;

    &.approved-instrumen {
        text-align: center;
    }
}

.top-btn-wrapper {
    position: fixed;
    top: 12px;
    right: 40px;
    z-index: 1200;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

button.btn-edit-save.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 169px;
    height: 38px;
    box-sizing: border-box;
    color: #FCFCFC;
    border: 1px solid #0F0F0F;
    background-color: #0F0F0F !important;

    &:hover {
        border-color: #FB7B34 !important;
        color: #FCFCFC !important;
        background-color: #0F0F0F !important;
    }

    &:disabled {
        border-color: #CCCCCC !important;
        background-color: #CCCCCC !important;
        color: #525252;

        &>span {
            color: #525252;
        }
    }

    &>span {
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;

    }
}

.portfolio-code {
    width: 100%;
}

.portfolio-sync {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;

    &>.ant-form-item>.ant-form-item-row {
        flex-direction: row;

        &>.ant-form-item-control {
            width: fit-content;
            flex-grow: 0;

            & .ant-form-item-control-input-content {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 10px;
            }
        }

        &>.ant-form-item-label {
            padding: 0;
            margin-top: 4px;
        }
    }
}

.pading-lr {
    padding: 0 32px 20px !important;
}

.portfolio-constraints {
    & .constraints-select {
        border: 1px solid #CCCCCC;
        border-radius: 24px;
    }

    & .bias-percentage {
        display: flex;
        align-items: center;
        width: 100%;
        font-family: 'Aeonik Quant';
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        color: #000000;

        &>.ant-input-number-input-wrap {
            margin: 0;
        }
    }
}

.header-wrapper.bias-header-wrapper,
.header-wrapper.fixation-header-wrapper {
    padding: 32px 40px 0;
}

.table-header-wrapper {
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1128px;
}

.grey-wrapper {
    width: 100%;
    background-color: #F9FAFB;
    padding: 15.5px 30px;
}

.investor_bias-wrapper {
    box-sizing: border-box;
    padding-bottom: 36px;
}

.investor_bias-wrapper>.bias-row-wrapper {
    padding: 16px 0 16px 30px;

    &:nth-child(even) {
        background-color: #F9FAFB;
    }

    & .ant-form-item {
        margin: 0;
    }
}

.bias-form-row {
    max-width: 1128px;
    flex-wrap: nowrap;
}

.del-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-btn-wrapper {
    max-width: 1128px;
}


.minmax-input {
    width: 100%;

    & input {
        font-family: 'Aeonik Quant' !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16.24px !important;
        text-align: left !important;
    }

}

.fixation-asset-name {
    font-family: 'Aeonik Quant';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    text-align: left;

}

.fixation-units {
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: center;

}

.fixation-checkbox {
    & .ant-checkbox {
        & .ant-checkbox-inner:after {
            border-color: #0F0F0F;
        }
    }
}

.wrap {
    min-height: calc(100vh - 280px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portfolio-constraints {
    & .ant-form-item-vertical .ant-form-item-label {
        padding: 0;
        padding-left: 24px;
        padding-bottom: 4px;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.24px;
        text-align: left;

    }

    & .ant-form-item-vertical .ant-form-item-control {
        flex: 1 1 48px;
    }
}

.currency-sek {
    &-wrapper {
        display: flex;
    }

    &-label {
        display: inline-block;
        padding-left: 24px;
        padding-bottom: 4px;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.24px;
        text-align: left;
        color: #0F0F0F;
    }

    &-text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding-left: 24px;
        width: 170px;
        height: 48px;
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        text-align: left;
        color: #0F0F0F;
    }
}

.exclude-holdings-table {

    & tr>th.exclude-holdings {
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 500;
        line-height: 18.56px;
        text-align: left;
        color: #525252;
    }

    & tr>th.exclude-isin>.ant-table-column-sorters {
        justify-content: center;
    }

    & tr>th.exclude-unitsNumber>.ant-table-column-sorters {
        justify-content: flex-end;
    }

    & tr>th.exclude-price>.ant-table-column-sorters {
        justify-content: flex-start;
    }

    & tr>th.exclude-fee>.ant-table-column-sorters {
        justify-content: center;
    }

    & .exclude-name {
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 500;
        line-height: 18.56px;
        text-align: left;
        color: #0F0F0F;
    }

    & .ant-form-item-control-input-content>td {
        width: 100%;
    }
}

.constraints-table-header.investor-bias-header {
    display: flex;
    align-items: center;
    gap: 16px;
}