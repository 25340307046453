label.opt-portfolio-form-subheading {
    display: inline-block;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: #0F0F0F;
    margin-bottom: 16px;

    &.fee {
        padding-left: 24px;
        margin-bottom: 4px;
    }
}

#opt-fees-portfolio .opt-portfolio-form-subheading {
    display: inline-block;
    margin-bottom: 12px;
}

#opt-fees-portfolio .m-bottom-24 {
    display: inline-block;
    margin-top: 24px;
}

.m-bottom-0 {
    margin-bottom: 0;
}

.opt-portfolio-text>span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
}

.fee-input-wrapper {
    margin-top: 24px;
}

.fees-input {
    display: flex;
    align-items: center;
    width: 440px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #DCDDDD;
    background: #FFF;
    color: #626363;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}

.ant-steps-label-vertical .ant-steps-item-content {
    width: 135px !important;
}

.portfolio-optimization-form .opt-portfolio-form-container {
    padding: 0;
}

.settings-inner-wrapper {
    padding: 0 24px;
    margin-top: 36px;
}

.orang-dots {
    color: Orange;
    padding: 0;
    margin: 0 5.5px;
    font-family: Aeonik Quant;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;

}

.settings-point-text {
    font-family: Aeonik Quant;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #0F0F0F;
}

.settings-row {
    max-width: 1128px;
}

.settings-withdrawn-input-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 26.5px;

    &>p {
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        text-align: left;

    }
}

.ant-col.currency-sek-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}