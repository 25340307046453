.header-buttons-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;

  button {
    font-size: 14px;
  }
}

.portfolio-table-wrapper {
  width: 100%;
  padding: 0 2rem;
  background-color: #FCFDFD;

  .tabs-position {
    z-index: 20;
    background-color: white;
    width: 100vw;
    position: fixed;
    top: 84px;
    left: 0;
  }

}

.choose-label-btn {
  border: 1px solid #138080;
  padding: 5px 15px;
  cursor: pointer;
}

.file-name {
  margin-left: 10px;
}

.tbd-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.portfolio-menu-item {
  font-family: 'Open Sans';
  font-size: 14px;
  padding: 5px 15px;
}

.portfolio-dropdown>.ant-dropdown-menu {
  border-radius: 8px;
}

.import-error {
  &-text {
    font-family: 'Open Sans';
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
  }

  &-massage {
    font-family: 'Open Sans';
    font-size: 12px;
    color: red;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.portfolio-container tr>th {
  font-weight: 600;
}

.holdings-text {
  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  text-align: center;
  color: #4D4D4D;
  margin-right: 8px;
}

.holdings-value {
  font-family: 'Aeonik Quant';
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  letter-spacing: 0.04em;
  color: #0F0F0F;
}

.holdings-value-wrapp {
  display: flex;
  align-items: center;
}

.holdings-value-fee {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 8px;
  background: rgba(99, 103, 195, 0.1215686275);
  color: #6367C3;
  margin-left: 10px;
  cursor: help;
}

.holdings-add-btn {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  background-color: #FCFCFC;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;


  &:hover {
    border: 1px solid #FB7B34;
    background-color: #FCFCFC !important;
  }

  & .ant-btn-icon.ant-btn-icon-end {
    margin: 0;
  }
}

.editable-row.is-odd {
  background-color: #F9FAFB;
}

.editable-row.padding {}

.sugg-portfolio {}