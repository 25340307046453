.onboarding-header {
    font-weight: 600;
    font-size: 35px;
    line-height: 48px;
    letter-spacing: 0.2px;
    margin-bottom: 82px;
}

.onboarding-container {
    width: 480px;
    //   background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.onboarding-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.2px;
    margin-top: 16px;
    margin-bottom: 12px;
}

.onboarding-subtitle {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    margin-bottom: 24px;
}

.checkbox-text {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
    margin-left: 15px;
}

.input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & .ant-input {
        margin-top: 0
    }

    & input+svg {
        margin-left: 16px;
    }

    & span+svg {
        margin-left: 5px;
    }

    & label {
        margin-bottom: 0;
    }

    & .ant-radio-inner::after {
        background-color: #799AA9;
    }

    & .ant-radio-checked::after {
        border: 1px solid #799AA9;
    }

    & .ant-radio-checked .ant-radio-inner {
        border-color: #799AA9;
    }
}

.ant-form-item input[type="radio"]+.ant-radio-inner {
    border-radius: unset;
}

.bankId {
    &-wrapper {
        width: 100%;
        padding: 0 30px;
    }

    &-header {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #626363;
    }

    &-button {
        width: 75px;
        height: 45px;
        background-color: #183E4F;
        background-image: url('./img/BankID_logo_neg.png');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 10px;
        cursor: pointer;

        &-wrapper {
            width: 100%;
            text-align: center;

            &>.bankId-button-login {
                margin: 16px auto;
            }
        }

        &-login {
            width: 120px;
            color: white;
            padding-left: 35px;
            font-weight: 700;
        }
    }

    &-or {
        width: 100%;
        text-align: center;
    }
}

.bankId-logo {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    background-image: url('./img/BankID_logo_black.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;

    &-wrapper {
        width: 100%;
        text-align: center;
    }
}

.modal-bankId {
    &-header {
        text-align: center;
        width: 380px;
        margin: 20px auto 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        text-align: center;
        color: var(--bankId-header-color);
        opacity: var(--bankId-header-opacity);

    }

    &-link {
        display: flex;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        width: 100%;

        &>a,
        &>a:active,
        &>a:visited {
            color: var(--fc-secondary);
        }

        & svg>g>path {
            fill: var(--login-forgot-arrow, #0F0F0F)
        }

        &>button {
            width: 100px;
            height: 40px;
            border-radius: 16px;
            cursor: pointer;
            margin-bottom: 24px;
        }
    }

    &-code-wrapper {
        width: 236px;
        height: 236px;
        margin: 0 auto;
        text-align: center;
        padding: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.white {
            background-color: #ffffff;
        }
    }

    &-text {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #626363;
        width: 360px;
        margin: 0 auto;
    }

    &-cancel {
        height: 40px;
        padding: 11px 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        color: #0F0F0F;
        cursor: pointer;
        border: 1px solid #0F0F0F;
        border-radius: 24px;
        margin: 0 auto;

        &.w360 {
            width: 360px;
        }

        &.w106 {
            width: 106px;
        }
    }
}

.number-is2fa-warning {
    font-family: Aeonik Quant;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #0F0F0F;
    padding: 0 24px;
}

.modal-2fa-wrapper {
    padding: 25px 20px;

}

.modal-twofactor-title {
    font-family: Aeonik Quant;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    text-align: center;
    color: #0F0F0F;
}

// new

.profile-wrap.onboarding-wrap {
    height: 100vh;

}

.onbording-card-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
}

.onbording-card {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.card-right {
        position: relative;
        padding-left: 10px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 1px;
            background: linear-gradient(0deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);
        }
    }
}

.onbording-card-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #FFAB00;
    font-size: 38px;
    font-weight: 700;
    line-height: 44.08px;
    letter-spacing: 0.04em;
    text-align: center;
}

.onbording-step-wrapper {
    margin-right: 109px;
    max-width: 327px;
}

.onbording-card-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 34.8px;
    text-align: left;
    color: #0F0F0F;
    margin: 24px 0;
}

.onbording-card-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
}

.onbording-card-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    text-align: left;
    color: #0F0F0F;

    &.complet-step {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

.onbording-card-subheader {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: left;
    color: #0F0F0F;
    margin-top: 24px;
    margin-bottom: 40px;

    &.step-two {
        margin-bottom: 24px;

    }
}

#onboarding-form-first {
    width: 360px;
    padding: 0;

    & .ant-space-vertical>.ant-space-item+div {
        margin-top: 16px;
    }
}

#onboarding-form-first .ant-radio-wrapper {
    display: flex;
    align-items: center;

    & svg {
        margin-left: 10px;
    }

    & .ant-radio+span {
        display: flex;
        align-items: center;
    }
}

.onbording-card-checkbox-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.input-personal-number {
    margin-bottom: 40px;
}

.start-onbording-btn {
    width: 100%;
}

.date-picker-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 200px;
    height: 40px;
    border: 1px solid #0F0F0F;
    border-radius: 24px;
    margin: 0 auto 40px auto;
    cursor: pointer;
}

#onboarding-form-first .ant-picker-input>input,
.onbording-card.card-right .ant-picker-input>input {
    padding-left: 16px;
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #0F0F0F !important;
        opacity: 1 !important;
    }
}

.onbording-card-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: left;
    color: #0F0F0F;
    margin-top: 24px;
    margin-bottom: 24px;
}

.onbording-card-meeting-link {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #0F0F0F;

    &>a,
    &>a:active,
    &>a:visited {
        color: #0F0F0F;

    }
}

.onbording-card-copied {
    color: #FFAB00;
}

button.optimise-btn.start-script-btn {
    box-sizing: border-box;
    margin-left: 4px;
    width: 360px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    background-color: #0F0F0F;

    & span {
        color: #FCFCFC;
    }

}

.card-right-wrap {
    margin-left: 109px;
    width: 400px;
    padding-right: 6px;
    overflow-y: auto;
}

.onboarding-processing-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.step-signing {
    max-width: 434px;
    width: 100%;
}

.onboarding-category-wrap {
    margin-top: 24px;
}

.finplan-datepicker.onboarding-datepicker {
    width: 360px;
}

#onboarding-form {
    padding: 0;

    & .finplan-datepicker.onboarding-datepicker input {
        padding-left: 14px;
        padding-right: 14px;
    }

    & label.ant-radio-wrapper.custom-radio-btn {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;

        &>.ant-radio {
            flex-shrink: 0;
        }
    }
}

.card-right-wrap {
    scrollbar-width: thin;
    /* Тонкий скроллбар */
    scrollbar-color: #8888888a #f1f1f1;
    /* Цвет ползунка и трека */
}

::-webkit-scrollbar {
    width: 3px;
    /* Ширина вертикального скроллбара */
}

/* Стили для трека (фон скроллбара) */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона трека */
}

/* Стили для ползунка (сам скроллбар) */
::-webkit-scrollbar-thumb {
    background-color: #8888888a;
    /* Цвет ползунка */
    border-radius: 1.5px;
    /* Закругление краев */
}

/* Стили при наведении на скроллбар */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Цвет ползунка при наведении */
}

.onboarding-category-disabled {
    cursor: not-allowed;
}

.onboarding-category-item {
    cursor: pointer;
}

.onboarding-question-wrap {
    width: 100%;
    padding: 24px;
    margin-bottom: 16px;
    color: #FCFCFC;
    background-color: #000000;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    &>ul {
        margin: 0;
        padding-left: 24px;
    }
}

.onboarding-question-other {
    width: 385px;
    padding: 24px;
    margin-left: auto;
    margin-bottom: 16px;
    color: #0F0F0F;
    background-color: #FCF2EB;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.onboarding-script-question {
    width: 434px;
    min-height: 90%;
    padding: 24px 6px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 72px;
    overflow-y: auto;
}

.next-btn-wrapper {
    width: 360px;
    margin-top: 16px;
    margin-left: 109px;
    margin-bottom: 48px;
}

.onboarding-form-body {
    width: 360px;

    & .ant-form-item {
        margin-bottom: 24px;
    }
}

.checkbox-block {

    &>.ant-space>.ant-space-item {
        margin-top: 24px;
    }
}

.ondoarding-space-wrap {
    padding-left: 24px;
}

.onboarding-upload-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-upload-title {
    align-self: flex-start;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
}

.onboarding-upload-btn {
    width: 360px;
    height: 40px;
    border: 1px solid #0F0F0F;
    border-radius: 24px;
    background-color: #FCFCFC;
    margin-top: 10px;
    margin-bottom: 5px;
}

.image-upload-wrapper.onboarding-upload {
    margin: 0;
    margin-bottom: 24px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
}

.onboarding-upload-filename {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    color: #4D4D4D;
}

.onbording-check-wrap {
    display: block;
    width: 360px;
    padding-left: 24px;
}

.onbording-check-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
}

.onboarding-upload-btn {
    &>span {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;

    }
}

.onboarding-question-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #808080;
    margin: 0;
}

.onboarding-question-sub {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
    margin-top: 16px;
}

#onboarding-form .custom-required label {
    display: inline-block;
}

.card-right-wrap.onboarding-risk-profile {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.types-label {
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
    }

    &-number {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        color: #0F0F0F;
        border: 1px solid #0F0F0F;
        background-color: #FFAB00;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.24px;
    }

    &-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 23.2px;
        color: #0F0F0F;
        margin: 0;
    }
}

.onboarding-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
}

.recommended-counter {
    &-wrap {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0;
        margin-bottom: 24px;
    }

    &-title {
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.2px;
        text-align: left;
        color: #0F0F0F;
    }

    &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        flex-shrink: 0;
        background: #FB7B34;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.24px;
        text-align: center;
        color: #0F0F0F;
    }
}

.recommended-card {

    &-wrapper {
        padding: 16px 24px;
        border: 2px solid #0F0F0F;
        border-radius: 12px;
        position: relative;
        margin-bottom: 16px;
    }

    &-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #0F0F0F;
        margin: 0;
        margin-bottom: 4px;
        padding-right: 20px;
    }

    &-charge {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        text-align: left;
        color: #4D4D4D;
        margin: 0;
        margin-bottom: 24px;
    }

    &-amount {
        font-size: 28px;
        font-weight: 500;
        line-height: 32.48px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #0F0F0F;
        margin: 0;
    }

    &-delete {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }
}

.recommended-divider {
    margin: 24px 0;
    border-top: none;
    height: 1px;
    background: linear-gradient(90deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);

}

#onboarding-form {
    & .ant-checkbox-wrapper {
        &>span.ant-checkbox+span {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.24px;
            text-align: left;
            color: #0F0F0F;
        }
    }

    & .ant-radio-wrapper {
        &>span.ant-radio+span {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.24px;
            text-align: left;
            color: #0F0F0F;
        }
    }
}

#onboarding-signing {
    & .ant-checkbox-wrapper {
        &>span.ant-checkbox+span {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.24px;
            text-align: left;
            color: #0F0F0F;
        }
    }
}

.onbording-step-wrapper .onboarding-category-wrap {
    & div.category-checkbx {
        background-color: white;
    }

    & div.category-checkbx.partially {
        background-color: #FFAB00;
    }

    & div.category-checkbx.full {
        background-color: #FB7B34;
        background-image: url('../../assets/check-icon.svg');
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.audioplayer-wrapper {
    position: fixed;
    bottom: 83px;
    left: calc(50% - 290px);
    width: 850px;
    min-height: 40px;
}

.upload-documents-wrapper {
    margin-top: 12px;
    padding-left: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 16px;
}

.name-upload-wrapper.upload-documents {
    width: 100%;
    justify-content: space-between;

    &>.name-uploaded {
        flex-grow: 1;
        text-align: left;
        cursor: pointer;
        margin: 0;
    }
}

.modal-content-paper.documents-preview {

    & .detail-container {
        box-sizing: border-box;
    }

    &>div {
        height: 100%;

        &>.ant-modal-content {
            padding: 40px;
        }

        & .ant-modal-body {}
    }
}

.upload-document-img {
    width: 100%;
    height: auto;
}

.onbording-card-subtitle.offline-meeting {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #4D4D4D;

    &.hour {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #0F0F0F;
        margin-top: 11px;
    }
}

.optimise-btn.start-script-btn.record-start-btn {
    margin: 51px 0px;
}

.ant-divider {

    &.custom-divider {
        display: flex;
        align-items: center;
        text-align: center;
        color: #808080;
    }

    &.custom-divider::before,
    &.custom-divider::after {
        content: '';
        flex: 1;
        height: 1px;
        border-block-start: none;
        background: linear-gradient(90deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);
    }

    &.custom-divider::before {
        margin-right: 8px;
    }

    &.custom-divider::after {
        margin-left: 8px;
    }
}

.reschedule-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    &>.date-picker-btn {
        margin-bottom: 0;
    }
}

.card-right-wrap.scheduled-meeting {
    box-sizing: border-box;
    width: 360px;
    height: 60vh;
    padding: 0;
    min-height: 440px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.recorder-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.recording-timer-wrapper {
    width: 100%;
    margin: 24px 0;
}

.recording-timer-box {
    height: 24px;
    width: fit-content;
    border-radius: 8px;
    padding: 2px 8px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    gap: 6px;

    background: #6367C31F;
}

.recording-timer {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #6367C3;
}

.card-right-wrap.scheduled-meeting.script {
    flex-direction: column;
    align-items: flex-start;
}

.onbording-card-header.complet-step.recorder {
    text-align: center;
}

button.optimise-btn.start-script-btn.recorder {
    width: 100%;
    margin: 0;
}

.player-btn {
    cursor: pointer;
}

.recorder-wrapper.mini {
    width: 360px;
    padding: 14px 28px;
    position: fixed;
    top: 10px;
    right: 410px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    border: 2px solid #FB7B34;
    border-radius: 13px;
    background-color: #ffffff;
    z-index: 1700;

    & .recording-timer-wrapper {
        margin: 0;
        margin-bottom: 16px;
        width: 100%;

        &>.recording-timer-box {
            margin: 0;
        }
    }

    & .player-btn-mini-wrap {
        display: flex;
        flex-wrap: nowrap;
        gap: 24px;

    }
}

.microfone-zone {
    flex-basis: 40%;
}

.button-zone {
    flex-basis: 60%;
}

.back-to-record-btn {
    position: fixed;
    top: 12px;
    right: 200px;
    z-index: 1700;
}

.risk-warning-wrapper {
    height: 40px;
    padding-left: 16px;
    color: #FB7B34;
}

.modal-reschedule-text {
    margin: 14px 0;
}

.modal-advisor-name.modal-reschedule {
    margin: 14px 0;
}