.ant-typography.modal-twofactor {
    &-header {
        text-align: left;
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 500;
        line-height: 18.56px;
        margin: 0 0 10px;
        color: #0F0F0F;
    }

    &-text {
        display: inline-block;
        text-align: left;
        margin-bottom: 1.5em;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        margin: 0 0 24px -10px;
        color: #0F0F0F;

    }
}

.modal-twofactor-wrapper {
    box-sizing: border-box;
    width: 100%;
    min-height: 207px;
    text-align: center;
    margin: 14px 0 24px;
    margin-left: -20px;
}

.twofactor-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>button {

        &:hover,
        &:focus {
            border-color: #4397A1;
            color: #4397A1;
        }
    }

    &>.twofactor-input {

        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 0 0 1px #367d853b
        }
    }
}

.ant-input.twofactor-input {
    width: 160px;
}

.modal-twofactor-list {
    padding-left: 16px;
}

.twofactor-input-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 269px;
    height: 48px;
    padding: 0px 32px;
    border-radius: 24px;
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #4D4D4D;
}