.tabs-wrapper {
  width: 100%;
  max-width: 1488px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 2rem;

}

.tabs-wrapper-inner {
  max-width: 1488px;
  width: 100%;
  margin: 0 auto;

  span {
    font-family: 'Lato', 'sans-serif';
    font-size: 14px;
    font-weight: 500;
    color: #8A8A8A;
    cursor: pointer;
    transition: .3s;
  }

  span:hover {
    color: #305572;
  }

  .ant-btn {
    padding-left: 40px;
    padding-right: 40px;
    margin-right: 30px;
    margin-bottom: 24px;
    height: 50px;

    &.ant-btn-active {
      border-bottom: 2px solid #305572;
      border-radius: 0px;

      span {
        color: #305572;
      }
    }
  }


  .tabs-client-name {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Lato', 'sans-serif';
    font-size: 14px;
    font-weight: 500;
    color: #8A8A8A;
  }
}

#portfolio-tabs.tabs-wrapper {
  margin-top: 24px;
  background-color: transparent;

  & .ant-btn {
    margin-right: 3px;
    padding: 0 24px;
    background-color: #ffffff;
  }

  & .ant-btn-active {
    background-color: #ffffffa1;
    border-left: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    border-bottom: none;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    span {
      color: #305572;
    }

  }

  & .ant-btn.btn-edit-edit {
    width: 216px;
    height: 44px;
    box-sizing: border-box;
    padding: 11px 22px;
    margin-right: 0;
    border-radius: 4px;
    border: 1px solid #1A3B5B;
    background-color: #1A3B5B;

    &:hover {
      background-color: #1a3b5bc2;

    }

    &>span {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;

    }
  }

  & .btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 24px;
  }


  & .btn-edit-save {
    width: 96px;
    height: 44px;
    box-sizing: border-box;
    padding: 11px 22px;
    border-radius: 4px;
    color: #ffffff;
    border: 1px solid #1A3B5B;
    background-color: #1A3B5B;

    &>span {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;

    }
  }

  & .btn-edit-cancel {
    width: 96px;
    height: 44px;
    padding: 11px, 22px;
    border-radius: 4px;
    border: 1px solid #1A3B5B;
  }


}

.transaction-update-btn {
  width: 120px;
  height: 36px;
  padding: 8.5px 17px 8.5px 17px;
  border-radius: 4px;
  color: #FFFFFF;
  background: #1A3B5B !important;
  display: flex;
  align-items: center;
  justify-content: center;

}