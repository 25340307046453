body.dark-theme {
  --container-border-radius: 13px;
}

body.light-theme {
  --container-border-radius: 10px;
}

.container {
  width: 480px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 15px;
}

.client-container.detail-container {
  background: var(--bg-secondary-color);
  border-radius: var(--container-border-radius);
}