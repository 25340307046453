.opt-portfolio-form {
  &-container {
    padding: 24px 20px 0 20px;
  }

  &-text {
    color: #626363;
    font-size: 12px;
    font-family: "Open Sans";
    letter-spacing: 0.2px;
  }

  &-row {
    align-items: flex-start;
    margin-bottom: 12px;

    & label.ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-bottom: 12px;

      &>.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #799aa9;
        border-color: #799aa9;
      }
    }
  }

  &-action {
    display: flex;
    align-items: center;

    &>img {
      cursor: pointer;
    }
  }

  &-col {
    box-sizing: border-box;
    padding-right: 35px;

    &.checkbox {
      display: flex;
      align-items: center;
    }

    &>.ant-form-item {
      margin-bottom: 0;
    }
  }

  &-label-horizon {
    display: inline-block;
    margin-bottom: 12px;
    width: 100%;
    color: #626363;
    font-size: 14px;
    font-family: "Lato";
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  &-range {
    flex-basis: 100%;

    &:hover .ant-slider-track {
      background-color: #30557282;
    }

    &>.ant-slider-track {
      background-color: #305572;
    }

    &>.ant-slider-handle {
      border: 2px solid #305572;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-number {
      padding: 0;
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #626363;
    }
  }

  &-checkbox {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
    cursor: pointer;
  }
}

.opt-select {
  padding-left: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #626363;

  &>.ant-input-number-input-wrap>input {
    margin-top: 4px;
  }

  &>.ant-input-number-input-wrap .ant-input-number-input {
    padding: 0;
  }

  & .ant-picker-input>input {
    font-size: 12px;
    color: rgb(138, 138, 138);
  }
}

select.opt-select {
  padding-left: 7px;
}

.fees-label {
  display: inline-block;
  width: 100%;
}

.add-deposit {
  width: 180px;
  height: 25px;
  font-size: 12px;
  margin-top: 20px;
}

.opt-portfolio-form-col .ant-input:hover,
.opt-portfolio-form-col .ant-input-number:hover,
.opt-portfolio-form-col .ant-input-number:focus,
.opt-portfolio-form-col .ant-input-number-focused,
.opt-portfolio-form-col .ant-input:focus,
.opt-portfolio-form-col .ant-picker:hover,
.opt-portfolio-form-col .ant-picker-focused {
  border-color: #626363;
  box-shadow: 0px 0px 0px 0px #62636380;
}

.opt-portfolio-form-col .ant-input-number::selection {
  background-color: #626363;
}

#create-portfolio-modal>.opt-portfolio-form-label {
  color: #626363;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.create-portfolio-modal .ant-modal-title {
  color: #000;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.financial-planning .portfolio-form-label {
  display: inline-block;
  margin-bottom: 12px;
}

.currency .ant-input-number-input-wrap {
  width: 165px;

  &::after {
    content: "kr";
  }

  & input {
    width: calc(100% - 14px);
  }

}

.currency {
  &.dig1 .ant-input-number-input-wrap {
    width: 24px;
  }

  &.dig2 .ant-input-number-input-wrap {
    width: 32px;
  }

  &.dig3 .ant-input-number-input-wrap {
    width: 40px;
  }

  &.dig4 .ant-input-number-input-wrap {
    width: 48px;
  }

  &.dig5 .ant-input-number-input-wrap {
    width: 56px;
  }

  &.dig6 .ant-input-number-input-wrap {
    width: 64px;
  }

  &.dig7 .ant-input-number-input-wrap {
    width: 72px;
  }

  &.dig8 .ant-input-number-input-wrap {
    width: 80px;
  }

  &.dig9 .ant-input-number-input-wrap {
    width: 88px;
  }

  &.dig10 .ant-input-number-input-wrap {
    width: 96px;
  }

  &.dig11 .ant-input-number-input-wrap {
    width: 104px;
  }

  &.dig12 .ant-input-number-input-wrap {
    width: 112px;
  }

  &.dig13 .ant-input-number-input-wrap {
    width: 120px;
  }

  &.dig14 .ant-input-number-input-wrap {
    width: 128px;
  }

  &.dig15 .ant-input-number-input-wrap {
    width: 136px;
  }

  &.dig16 .ant-input-number-input-wrap {
    width: 144px;
  }

  &.dig17 .ant-input-number-input-wrap {
    width: 152px;
  }

  &.dig18 .ant-input-number-input-wrap {
    width: 160px;
  }
}

#financial-planning-form {
  width: 100%;

  & .portfolio-container-title {
    margin-top: 0;
  }

  & .plan-form-radio {
    & span.ant-radio span.ant-radio-inner {
      border-radius: 50%;
      border-color: #799AA9;
      background-color: transparent;

      &::after {
        background-color: #248926;
      }
    }

    & span {
      color: #626363;
      font-size: 12px;
      font-family: 'Open Sans';
      letter-spacing: 0.2px;
    }
  }

  & .opt-select {
    box-sizing: border-box;
    padding-left: 0;

  }

  & .ant-picker-input>input {
    padding-left: 16px;
    box-sizing: border-box;
  }

  & .ant-input-number-input-wrap {
    height: 100%;
    display: flex !important;
    align-items: center;
    padding-left: 16px;
  }
}

#financial-planning.detail-container.portfolio-container>div.header-wrapper {
  margin: 0;
}

#financial-planning-deposit .opt-portfolio-form-action,
#financial-planning-withdrawal .opt-portfolio-form-action {
  margin-top: 16px;
}

#financial-planning,
#financial-planning-withdrawal,
#financial-planning-deposit {
  & .text-left {
    text-align: left !important;
  }
}

.finplan-block-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: left;
  color: #0F0F0F;
  margin: 0;
}

.finplan-title-dots {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: left;
  color: #FB7B34;
}

.finplan-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  color: #0F0F0F;
  margin: 0;
  // padding-left: 40px;
  padding-bottom: 32px;
}

.finplan-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: left;
  color: #0F0F0F;
  margin-top: 32px;
  margin-bottom: 40px;
}

// .fin-plan-level {
//   width: 100%;
// }
.finplan-barrace {
  width: 100%;
}

th.fin-plan-count>div.ant-table-column-sorters {
  justify-content: center;
}

th.fin-plan-time-horizon>div.ant-table-column-sorters {
  justify-content: center;
}

th.fin-plan-name,
td.fin-plan-name {
  padding-left: 30px !important;
}

.fin-plan-name .ant-form-item-control-input-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  text-align: left;
  color: #0F0F0F;
}

.fin-plan-count .ant-form-item-control-input-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #525252;
}

.fin-plan-time-horizon .ant-form-item-control-input-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #525252;
}

.finplan-table {

  & th span.ant-table-column-title,
  & th.ant-table-cell.fin-plan-assigned {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: left;
    color: #525252;
  }
}

.race-value {
  text-align: left;
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  text-align: left;
  color: #0F0F0F;
  margin: 0;
}

.finplan-table {
  position: relative;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0;
}

.finplan-summary-container {
  width: 100%;
  background-color: transparent;
  padding: 72px 0 0 0;
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.finplan-modal-wrapper {
  max-width: 360px;
  margin: 0 auto;
}

.plan-create-modal-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: center;
  color: #232323;
  margin: 0;
  margin-top: 67px;
}

.plan-create-modal-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: center;
  color: #262626;
  margin-top: 36px;
  margin-bottom: 24px;
}

.plan-renge-wrapper {
  margin-top: 24px;
  background-color: #FCFCFC;
  border-radius: 20px;
  padding: 11px 9px 15px 9px;
}

.plan-renge-title {
  margin: 0;
  margin-bottom: 44px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: #0F0F0F;
}

.range-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.range-min-max {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: right;
  color: #0F0F0F;
  flex-grow: 0;
}

.plan-slider-item {
  flex-grow: 1;
}

div.ant-slider-rail.plan-slider-rail {
  border-radius: 20px;
}

div.ant-slider-track.plan-slider-track {
  border-radius: 20px;
  background: linear-gradient(90deg, #F8D9C5 0%, #F0A67A 97%);
}

div.ant-slider-handle.plan-slider-handle {
  width: 20px;
  height: 20px;

  &::before {
    width: 20px;
    height: 20px;
  }

  &::after {
    background-color: #FB7B34;
    background-image: url('./array-range.svg');
    background-size: cover;
  }
}

button.finplan-submit-btn {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 81px;
}

.plan-wrap {
  padding: 0 24px;
}

.finplan-info {
  width: 100%;
  background: #FCFCFC;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  padding: 24px;
}

.finplan-name {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #0F0F0F;
}

.finplan-end-date-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  text-align: center;
  color: #262626;
}

.finplan-end-date {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #262626;
}

.finplan-amuont {
  font-size: 28px;
  font-weight: 500;
  line-height: 32.48px;
  letter-spacing: 0.04em;
  text-align: center;
  columns: #0F0F0F;
}

.finplan-achived-persent {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #6367C3;
}

.finplan-achived-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  text-align: left;
  color: #4D4D4D;
}

.finplan-by-any {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  text-align: center;
  color: #6E6E6E;
}

.finplan-divider {
  margin: 24px 0;
  border-top: none;
  height: 1px;
  background: linear-gradient(90deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);
}

.finplan-info-header {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  color: #0F0F0F;
  margin-bottom: 24px;
}

.finplan-info-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  color: #0F0F0F;
}

.finplan-item-add-btn {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 11px 24px;
  background-color: #FCFCFC;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.filter-itest-divider {
  margin: 20px 0;
  background: linear-gradient(90deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);

}

.filter-modal {

  & input[type="number"]::-webkit-inner-spin-button,
  & input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type="number"] {
    -moz-appearance: textfield;
  }
}

.tabs-filter-row {
  margin-top: 32px;
  border-bottom: 1px solid #F9FAFB;
}

.tabs-filter-btn {
  padding: 12.5px 32px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #4D4D4D;

  &.active {
    border-bottom: 2px solid #0F0F0F;
  }
}

.portfolio-menu-item.tabmore-menu-items,
.tabmore-menu-items span {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #4D4D4D;
}

.finplan-items-header {
  margin-top: 16px;
  padding: 15px 10px;
  background-color: #F9FAFB;
}

.finplan-items-name {
  display: flex;
  flex-basis: 36.35%;
  padding-left: 12px;
}

.finplan-items-amount {
  display: flex;
  flex-basis: 30%;
}

.finplan-items-start {
  display: flex;
  flex-basis: 15%;
}

.finplan-items-end {
  display: flex;
  flex-basis: 15%;
}

.finplan-item-wrapper {
  display: flex;
}

.finplan-category-wrapper {
  min-height: 75vh;
  padding: 24px 72px 0 57px;

  background-color: #FFFFFF;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background: linear-gradient(180deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);
  }

}

.finplan-category-header {
  font-size: 30px;
  font-weight: 700;
  line-height: 34.8px;
  text-align: left;
  color: #0F0F0F;
  margin: 0;
  margin-bottom: 24px;
}

.finplan-category-form-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: left;
  color: #0F0F0F;
}

.finplan-category-form-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: left;
  color: #0F0F0F;
}

.finplan-category-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: left;
  color: #0F0F0F;
}

.finplan-category-list-wrapper {
  width: 100%;
  border: 2px solid #000000;
  border-radius: 24px;
  padding: 16px;
}

.category-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  &.selected {
    border-radius: 24px;
    background-color: #FB7B34;

    & .category-checkbx {
      background-color: #FFAB00;
    }
  }
}

.category-colunm {
  display: flex;
  align-items: center;
  gap: 8px;
}

.category-checkbx {
  width: 24px;
  height: 24px;
  border: 1px solid #0F0F0F;
  border-radius: 50%;
  margin-right: 8px;
}

.category-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #0F0F0F;
}

.category-count {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: center;
  color: #0F0F0F;
  background-color: #FB7B34;
}

.finplan-category-form-wrapper {
  width: 50%;
  padding: 24px 72px 45px 110px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  background-color: #FFFFFF;
}

.finplan-category-form-description {
  margin-bottom: 40px;
}

.finplan-item-wrap {
  padding: 24px 0;
  background-color: #FFFFFF;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.instrument-block-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.image-upload-wrapper {
  margin: 24px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  & input.item-img-upload {
    display: none;
  }
}

.name-upload-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.name-uploaded {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #262626;
}

.clear-uploaded {
  cursor: pointer;
}

textarea.finplan-item-description {
  width: 360px;
  min-height: 130px;
  border-radius: 24px;
  padding: 14.5px 24px;
}

.finplan-datepicker-wrap {
  width: 172px;
  margin: 0;

  & .ant-picker.ant-picker-outlined {
    padding-left: 24px;
  }
}

.finplan-datepicker {
  width: 100%;
  height: 56px;
  border-radius: 24px;
}

#finplan-item {
  width: 360px;
}

.range-min-max.fin-plan-range {
  margin: 0;
}

div.plan-item-necessity {
  margin: 0 8px;
  flex: 1;
}

.plan-renge-title.plan-renge-necessity {
  margin-bottom: 30px;
}

.finplan-save {
  width: 100%;
  margin-top: 40px;
}

.finplan-tags-coun {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #808080;
}

.finplan-items-row {
  // height: 92px;
  padding: 24px 0;
  cursor: pointer;

  & .finplan-items-name {
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    color: #141414;
    padding-left: 24px;
    position: relative;
  }

  &:hover {
    & .finplan-items-name .hover-dot {
      width: 11px;
      height: 11px;
      position: absolute;
      top: 13px;
      left: 2px;
      background-color: #FB7B34;
      border-radius: 50%;
    }

    & .finplan-items-amount {
      color: #FB7B34;
    }
  }

  & .finplan-items-amount {
    font-size: 28px;
    font-weight: 400;
    line-height: 32.48px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #808080;
  }

  & .finplan-items-end,
  .finplan-items-start {
    font-size: 28px;
    font-weight: 400;
    line-height: 32.48px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #808080;
  }
}

.finplan-items-card-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 24px 48px;
  background-color: #FCFCFC;
  border-radius: 20px;

  & .card-info-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
    margin-bottom: 16px;
  }

  & .card-info-endtime {
    font-size: 28px;
    font-weight: 400;
    line-height: 32.48px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #808080;
    margin: 0;
    margin-bottom: 18px;

    &>span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: #808080;
    }
  }

  & .card-info-amount {
    font-size: 42px;
    font-weight: 400;
    line-height: 48.72px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #FB7B34;
    margin: 0;
    margin-bottom: 32px;
  }
}

.finplan-items-card-info {
  width: 50%;
}

.info-count-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.info-count-cell {
  flex-basis: 33%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-count-title {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #808080;
}

.info-count-dig {
  height: 24px;
  padding: 2px 8px;
  background: #6367C31F;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6367C3;
}

.finplan-items-card-action {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.finplan-items-close-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0F0F0F;
}

.expenses-parts-wrapper {
  width: 100%;
  display: flex;
}

.expenses-parts-card {
  position: relative;
  border-left: 1px dashed #CCCCCC;

  &.first>div {
    border-bottom-left-radius: 10px;
  }

  &>.expenses-parts-card-dot {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #CCCCCC;
    top: -7px;
    left: -4px;
  }
}

.expenses-parts-card-amount {
  margin: 0;
  margin-bottom: 8px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  text-align: left;
  color: #0F0F0F;
  white-space: nowrap;
}

.expenses-parts-card-category {
  margin: 0;
  margin-bottom: 97px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: #4D4D4D;
}

.finplan-items-card-img {
  width: 290px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    width: 100%;
    height: auto;
  }
}

.view-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px,
}