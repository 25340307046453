.table-skeleton {
    display: inline-flex;
    justify-content: center;
    width: 100%;

    &>span {
        width: 98%;
        height: 80px;
        margin-bottom: 16px;
    }
}

.suggestions-table {
    padding-bottom: 20px;
}

.pf-data {
    display: inline-block;
    height: 24px;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

    &.green-pf-data {
        background: #139E191F;
        color: #139E19;
    }

    &.red-pf-data {
        background: #DC180C1F;
        color: #DC180C;
    }
}

.suggestions-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
}

.suggestions-create-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    text-align: left;
    color: #525252;
}

.filter-date-title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
}

.modal-translation {
    min-height: 375px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &>div {
        width: 100%;
    }

    .ant-modal-content {
        padding: 0;
        padding-bottom: 24px;
    }
}


.modal-transaction-header {
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    margin: 0;
    border-bottom: 1px solid #E1E1E9;
}

.modal-action-btn-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}

.transaction-table-wrap {
    padding: 18px 27px;
    height: 587px;
    height: 70vh;
    min-height: 200px;
    background-color: #ffffff;
    overflow-y: auto;
}

.table-buy-sell tbody.ant-table-tbody>tr>td {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #525252;
}

.table-buy-sell thead.ant-table-thead>tr>th {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #525252;
}

#filter-config {
    & .filter-date-title {
        padding-left: 16px;
    }

    & .ant-col.ant-form-item-label {
        padding: 0 0 0 16px;

    }
}

button.action-btn.advisor-action-btn.market {
    width: fit-content;
    padding: 0 8px;
}

.ant-select-multiple.ant-select-selection-wrap {
    align-self: center;
}

.type-download {
    & .custom-radio-btn>span {
        flex-shrink: 0
    }
}

.note-bottom {
    padding-left: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
}

.ant-table-cell.value {
    white-space: nowrap;
}

.ant-table-cell.date {
    max-width: 120px;
    min-width: 120px;
}

span.transaction-set-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.56px;
    letter-spacing: 0.04em;
}

.editable-row.bold>td.ant-table-cell.date {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #FB7B34;
}

.editable-row>td.ant-table-cell.date {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #525252;
}