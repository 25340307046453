.login {
    &-wrapper {
        max-width: 1440px;
        min-height: 100vh;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0 auto;
        background-color: var(--bg-primary-color);
        padding: var(--login-wrapper-padding);
    }

    &-black-dots {
        width: 90px;
        height: 343px;
        position: absolute;
        top: calc(50% - 171px);
        right: -45px;
    }

    &-picture {
        flex-basis: 50%;
        position: relative;

        &>img {
            width: 100%;
            height: auto;
            border-radius: var(--logo-img-border-radius);
            opacity: var(--logo-img-opacity);
        }

    }

    &-form {
        flex-basis: 50%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-title {
            width: 100%;
            display: flex;
            justify-content: center;

            &>img {
                width: 100%;
                max-width: 227px;
            }
        }
    }

    &-btn-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        &>button {
            display: flex;
            align-items: center;
        }
    }

    &-divider {
        margin: 32px 0;
        border-top: none;
        height: 1px;
        background: linear-gradient(90deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);
        ;
    }
}

#login {
    min-width: 360px;
    margin-top: 48px;

    & .forgor-btn {
        padding: 5px 9px;
        border: var(--login-forgot-btn-border, none);
        border-radius: var(--login-forgot-btn-br);
        color: var(--fc-secondary);

        & svg>g>path {
            fill: var(--login-forgot-arrow, #0F0F0F)
        }
    }
}

#login-form-remember-switch {
    min-width: 40px;
    height: 22px;
    border: 1px solid var(--login-switch-border-color, #0F0F0F);
    background-color: var(--login-switch-bgc);

    &+label {
        margin-left: 8px;
        cursor: pointer;
    }

    &:not(.ant-switch-checked) {
        & .ant-switch-handle::before {
            border: 1px solid var(--login-switch-handle-border);
            background-color: var(--login-switch-bgc);
        }

        ;
    }

    &>.ant-switch-handle::before {
        border: 1px solid var(--login-switch-handle-border);
        background-color: var(--login-switch-handle-border);
    }
}

.login-submit-btn-wrapper {
    margin-top: 10px;

    &>button.ant-btn.ant-btn-block {
        height: 44px;
        background: var(--login-btn-bgc);
        padding: 0px 32px;
        border-radius: 24px;
        border-color: var(--login-btn-bgc);
        color: var(--login-btn-color);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

        &:hover {
            background: var(--login-btn-bgc-hover);
            border-color: var(--login-btn-border-hover);
            color: var(--login-btn-color);

        }
    }
}

.login-bankid-btn-wrapper {
    &>button {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        border: 1px solid #000000;
        border-radius: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

    }
}

.login-bottom-link {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &>.text {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        color: var(--fc-secondary);

    }

    &>.link {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--fc-secondary);

        &+svg>g>path {
            fill: var(--login-forgot-arrow, #0F0F0F)
        }
    }
}

input#sign-up-form_email:-internal-autofill-selected,
input#sign-up-form_email:-webkit-autofill,
input#sign-up-form_email:-internal-autofill-previewed,
input#sign-up-form_password:-internal-autofill-selected,
input#sign-up-form_password:-webkit-autofill,
input#sign-up-form_password:-internal-autofill-previewed,
input#forgot-password-form_email:-internal-autofill-selected,
input#forgot-password-form_email:-webkit-autofill,
input#forgot-password-form_email:-internal-autofill-previewed,
input#login_password:-internal-autofill-selected,
input#login_password:-webkit-autofill,
input#login_password:-internal-autofill-previewed,
input#login_email:-internal-autofill-selected,
input#login_email:-webkit-autofill,
input#login_email:-internal-autofill-previewed {
    background-color: var(--bg-secondary-color) !important;
    -webkit-text-fill-color: var(--fc-secondary) !important;
    transition: background-color 5000s ease-in-out 0s;
}

#login,
#sign-up-form,
#forgot-password-form {
    & label {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.24px;
        color: var(--login-label-fc);

    }

    & input.login-input.ant-input-outlined,
    & input.ant-input-outlined.custom-input,
    & span.login-input.ant-input-outlined {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        width: 360px;
        height: 48px;
        border-radius: 24px;
        padding: 8px 24px;
        background-color: var(--bg-secondary-color);
        color: var(--fc-secondary);

        &::placeholder {
            color: var(--login-placeholder-fc);
        }
    }

    & span.login-input.ant-input-outlined>input {
        color: var(--fc-secondary);

        &::placeholder {
            color: var(--login-placeholder-fc);
        }
    }

    & .ant-col.ant-form-item-label {
        padding: 0 0 4px 24px;
    }
}