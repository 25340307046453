.admin-tools-wrapper {
  width: 100%;
  padding: 0 20px;
  margin-top: 24px;
}

.instrument-form {
  padding: 0 24px;

  .instrument-form-block {
    box-sizing: border-box;
  }
  &-iner-wrapper {
    padding: 48px 24px;
  }
}