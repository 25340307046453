.custom-inputnumber {
    width: 100%;
    max-width: 360px;
    height: 48px;
    border-radius: 24px;

    & .ant-input-number-input-wrap {
        padding-left: 24px;
        margin-top: 10px;

        & > input {
            padding-left: 0;
            font-family: 'Aeonik Quant';
            font-size: 16px;
            font-weight: 400;
            line-height: 18.56px;
            color: #0F0F0F;
        }
    }

    & .ant-input-number {
        border-radius: 24px;
        padding-left: 24px;
    }

    & .ant-input-number-handler-wrap {
        margin-right: 16px;
        border-right: 1px solid rgb(217, 217, 217);
    }

    &.persent {

        & .ant-input-number-handler-wrap {
            margin-right: 56px;
        }
    }

}