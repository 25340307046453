.forgot-password-confirm {
    width: 500px;
    padding: 40px;
    background-color: white !important;

    p {
        background-color: lightgrey;
        text-align: center;
        padding: 5px 0;
    }
}

.forgot-password-title {
    font-family: Aeonik Quant;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: center;
    color: #0F0F0F;
    margin-bottom: 32px;
}

.forgot-password-text {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    color: #4D4D4D;
    margin-top: 0;
    margin-bottom: 10px;
}

.forgot-password-mail {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: center;
    color: #0F0F0F;
    margin-top: 0;
    margin-bottom: 32px;
}

.forgot-password-mail-pic {
    display: flex;
    justify-content: center;
}

.forgot-password-nomail {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: center;
    color: #0F0F0F;
    margin-top: 32px;
    margin-bottom: 10px;
}

.forgot-password-list {
    width: 100%;
    text-align: left;
    margin: 0;
    margin-bottom: 24px;

    &>li {
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        text-align: left;

    }
}