body.dark-theme {
    --switcher-bcg: #1B1918;
    --switcher-color: #8B8A8A;
    --switcher-btn-bcg: #2B2726;
    --switcher-btn-color: #FFFFFF;
}

body.light-theme {
    --switcher-bcg: #FCFDFD;
    --switcher-color: #2B2726;
    --switcher-btn-bcg: #FCFCFC;
    --switcher-btn-color: #000000;
}

.top-switcher-wrapper {
    box-sizing: border-box;
    width: 154px;
    height: 32px;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    padding: 4px;
    border-radius: 24px;
    background: var(--switcher-bcg);
    cursor: pointer;
}

.switcher-button-wrap {
    width: 75px;
    height: 24px;
    padding: 0 8px;
    border-radius: 24px;

    position: absolute;
    top: 4px;
    left: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    background: var(--switcher-btn-bcg);
    transition: left 0.3s ease;

    &.second {
        left: 74px;
    }
}

.switcher-button-text {
    color: var(--switcher-btn-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.switcher-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: 0 4px;
    color: var(--switcher-color);
}