.personal-number-row {
  display: flex !important;
  justify-content: space-between;
  // border-bottom: 2px solid lightgrey;
  padding: 20px 10px !important;
  box-sizing: border-box;

  .input-block {
    display: flex;
    height: fit-content;

  }

  .row-title {
    display: inline-block;
    width: 140px;
    margin-right: 20px;
    height: fit-content;
  }

  .profile-input-pn {
    width: 160px;
    margin-right: 20px;
  }

  .profile-row-value {
    width: fit-content;
  }
}


.profile-bankid-btn.disabled {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-row:hover {
  background-color: #e7e7e7;
  transition: .3s;
}

.inputs-row-block {
  width: 320px;
  justify-content: space-between;

  input {
    width: 150px;
  }

  input+input {
    margin-left: 15px;
  }
}

.ant-switch.twoAuth-swich-button {
  width: 120px;
  background-color: #F0F0F0;

  &:focus {
    box-shadow: none !important;
  }

  .ant-switch-checked:focus {
    box-shadow: none !important;
  }

  .ant-switch-inner {
    color: #000;
  }

  .ant-switch-handle {
    &:before {
      background-color: #718F83;
    }
  }

  &.twoAuth-swich-button_off {
    cursor: help;
  }

}

button.profile-bankid-btn {
  width: 360px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid #000000;
  transition: box-shadow 0.3s ease;
  background-color: transparent !important;
  color: #0F0F0F !important;
  cursor: pointer;

  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;


  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.banckID-img {
  width: 21px;
  height: 20px;

  & img {
    width: 100%;
    height: auto;
  }

}