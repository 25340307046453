.support-container ul li {
    list-style: none;
    padding: 5px 0;
}

.support-container ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: fit-content;
}

.support-container ul div {
    margin-right: 50px;
}

.support-container {
    padding-top: 99px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    height: 60vh;
    width: 100%;

    .fields-names {
        font-weight: bold;
    }
}

.support-link-text,
.support-link-text:hover,
.support-link-text:active {
    color: #000;
}

.suport-header-title {
    font-family: Aeonik Quant;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    margin: 0;
}

.support-inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    border-radius: 13px;

    &>.support-img-wrapper {
        flex-basis: 50%;
        max-width: 564px;
    }

    &>.support-text-wrapper {
        flex-basis: 50%;
        max-width: 564px;
    }

    &>.support-img-wrapper {
        position: relative;
        box-sizing: border-box;
        border-top-left-radius: 13px;
        border-bottom-left-radius: 13px;

        >img {
            border-top-left-radius: 13px;
            border-bottom-left-radius: 13px;
            height: 100%;

        }

        &>svg {
            position: absolute;
            top: 22%;
            left: 45%;
        }
    }

    & .suport-header {
        font-family: Aeonik Quant;
        font-size: 30px;
        font-weight: 700;
        line-height: 34.8px;
        text-align: center;
        margin-top: 137px;
        margin-bottom: 79px;
    }

    & .support-label {
        width: 100%;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;
        text-align: center;
        margin-bottom: 16px;

    }

    & .support-text {
        display: block;
        width: 100%;
        font-family: Aeonik Quant;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.2px;
        text-align: center;
        margin-bottom: 56px;
        color: #0F0F0F;
        &:active {
            color: #0F0F0F;
        }
    }
}