.instrument-button-wraper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
}

.ant-select.ant-select-outlined.custom-search-input {
    width: 100%;
    max-width: 360px;
    height: 48px;
    border-radius: 24px;
    font-family: 'Aeonik Quant';
    font-size: 16px !important;
    font-weight: 400;
    line-height: 18.56px;
    color: #0F0F0F;
    border: 1px solid #d9d9d9;

}
