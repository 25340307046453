.card-container {
  display: flex;
  flex-direction: column;
  width: 233px;
  background: #ffffff;
  border: 1px solid #799aa9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}

.is-important {
  display: flex;
  justify-content: flex-end;
  min-height: 9px;
}

.card-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  height: 48px;
}

.card-header {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #799aa9;
}