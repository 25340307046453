h1.onboard-component-title {
  display: flex;
  justify-content: center;
  margin: 75px 0 82px;
}

.info-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.info-client {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #626363;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.line {
  display: inline-block;
  width: 100%;
  height: 1px;
  border: 1px solid #dcdddd;
  margin-top: 40px;
  margin-bottom: 6px;
}

.sign-agreement {
  margin-bottom: 40px;
}

.go-back {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 30px;
  letter-spacing: 0.2px;
  color: #626363;
  cursor: pointer;

  & svg {
    margin-right: 6px;
  }
}

.approve-button {
  background: #81929A;
  width: 440;
  margin: 40px auto 50px;
}