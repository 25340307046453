body.dark-theme {
    --suffix-icon-color: #4D4D4D;
    --border-color: #2B2726;
    --bcg-color: #1B1918;
}

body.light-theme {
    --suffix-icon-color: #000000;
    --border-color: #E6E6E6;
    --bcg-color: #fff;
}


.custom-password .ant-input-suffix {
    cursor: pointer;

    &>svg>path {
        fill: var(--suffix-icon-color);
    }
}

.ant-input-outlined.ant-input-password.custom-password,
.ant-input-outlined.ant-input-password.custom-password.ant-input-affix-wrapper-focused {
    height: 48px;
    border-radius: 24px;
    border: 1px solid var(--border-color);
    background: var(--bcg-color);
    color: var(--fc-secondary);

    &:not(.ant-input-disabled):hover {
        background: var(--bcg-color);

    }
}