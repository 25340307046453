.container-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    padding: 31px 40px;
    margin: 0;
}

.model-table-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
}

.model-table-subname {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    margin: 0;
}

.model-table-return {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: center;

    & .ant-table-column-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18.56px;
        color: #525252;
    }

    &>.ant-table-column-sorters {
        justify-content: left;
    }

}

th.exclude-holdings.exclude-name.model-name,
td.exclude-holdings.exclude-name.model-name {
    padding-left: 30px;
}

.titlerow-btn-wrapper {
    display: flex;
    align-items: center;
    padding-right: 24px;

    & .portfolio-creat-download {
        height: 40px;
    }
}

.upload-model-btn {
    background-color: #FCFCFC;
}

.custom-select.asset-select-models .ant-select-selection-item {
    display: flex;
    gap: 10px;
    align-items: center;
}

.asset-option-status {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #000000;
    margin-right: 10px;

    &.partially {
        background-color: #FFAB00;
    }

    &.full {
        background-color: #FB7B34;
        background-image: url('../../assets/check-icon.svg');
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.model-series-headers {
    background-color: #F9FAFB;
    padding: 15px 0;
}

.model-series-headers-cell {
    flex-basis: 7.69%;
    text-align: center;
}

.model-series-row-cell {
    flex-basis: 7.69%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 10px;
}

.model-series-row {
    height: 80px;
    border-bottom: 1px solid #F9FAFB;

    &.is-odd {
        background-color: #F9FAFB;
    }
}

input.custom-inputnumber.model-series {
    width: 100%;
}

.out-container-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;

    margin: 0;
    padding: 0;
    padding-left: 24px;
}

.model-modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    text-align: center;
    margin-bottom: 36px;

}

#new-model-form {
    width: 360px;
    margin: 0 auto;
    padding: 0;
}

.modal-action-btn.save-btn.model-modal-btn {
    width: 100%;
}

.model-modal-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    margin: 0;
    margin-bottom: 24px;

}

.clone-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 0 28px;
}

.modellist-wrapper {
    background-color: #F6F7F9;
    position: relative;
    min-height: calc(100vh - 160px);
    max-height: calc(100vh - 160px);
    overflow-y: auto;
}

.model-tabs-wrapper {
    flex-wrap: nowrap;
    margin: 24px 0;
    padding: 1px 24px;
    gap: 24px;
}

.model-tabs-column {
    flex-basis: 50%;
}

.model-tab-btn-wrapper {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    padding: 0 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #0F0F0F;
    border: 1px solid #0F0F0F;
    background-color: #FCFCFC;
    cursor: pointer;

    &.selected {
        background-color: #0F0F0F;
        color: #FCFCFC;

        &>.model-tab-btn-round {
            background-color: #FB7B34;
            background-image: url('../../assets/check-icon.svg');
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &>.model-tab-btn-round {
        width: 24px;
        height: 24px;
        border: 1px solid #000000;
        border-radius: 50%;
        background-color: #FCFCFC;
    }

}

.model-tab-btn-title {
    margin: 0;
}

.series-data-cell {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;

}

#primitives-form {
    padding: 0;
}

.primitives-header {
    padding: 25.5px 30px;
    background-color: #F9FAFB;
}

.primitives-title-class,
.primitives-title-override,
.primitives-header-value,
.primitives-title-value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    color: #525252;
}

.primitives-title-override.cell {
    text-align: left;
    margin-left: -24px;
}

.primitives-title-override.cell {
    text-align: left;
    margin-left: -24px;
}

.primitives-title-value {
    justify-content: center;

    &.cell {
        margin-left: -24px;
    }
}

.primitives-cell-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
    margin-bottom: 5px;
}

.primitives-cell-category {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #808080;
    margin: 0;
}

.error-del-modal {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: red;

}

.primitives-row {
    width: 100%;
    margin: 0 20px;
    padding: 16px 10px;
    background-color: #FFFFFF;

    &.is-odd {
        background-color: #F9FAFB;
    }
}

.ant-select.ant-select-outlined.custom-select.label {
    max-width: 100%;
}

#model-constraints-form {
    padding: 0;
}

.ratio-constraints-wrapper {
    padding: 0 32px 48px;
}

.ratio-constraints-row {
    max-width: 812px;
    gap: 48px;
}

.custom-required.ratio-constraints-asset {
    margin-bottom: 0 !important;
}

.ratio-constraints-divider.ant-divider-horizontal {
    margin: 2px 0;
    min-width: 360px;
    width: 360px;
    color: #4D4D4D;
    border-color: #4D4D4D
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* Убирает стрелки в браузерах на основе WebKit */
    margin: 0;
    /* Убирает возможные дополнительные отступы */
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Убирает стрелки в Firefox */
}

.controll-wrapper.controll-constraints {
    width: 100px;
}

.ratio-constraints-divider.ant-divider-horizontal.weight {
    min-width: 100px;
    width: 100px;
}

.controll-constraints-item {
    margin-bottom: 0;
}

.control-min-value {
    padding: 0;
    background-color: transparent;
}

.controll-constraints-item {
    & .ant-col.ant-form-item-label {
        padding: 0 !important;
        text-align: center !important;
    }

    & .ant-input-outlined {
        padding-top: 4px;
    }

    & .ant-input-outlined:hover,
    & .ant-input-outlined:focus {
        border: none;
        background-color: transparent;
    }

    & .ant-form-item-control-input {
        min-height: auto;
    }

    & .ant-form-item-control-input-content {
        height: 22px;
        display: flex;
        align-items: center;
    }

    & .controll-constraints.without-label .ant-form-item {
        margin-bottom: 0;
    }
}

.ratio-constraints-row {
    margin-bottom: 24px;

    & .ant-col.comparison-column>.ant-form-item {
        margin-bottom: 0;
    }

    & .ant-form-item.weight-data {
        width: 54px;
    }
}

.titlerow-btn-wrapper.download-series {
    gap: 24px;
}

.model-table-return.status {
    text-transform: capitalize;
}

.modal-select-status {
    font-size: 18px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: center;
    color: #262626;
    margin-bottom: 24px;
}