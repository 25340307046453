.header {
  display: flex;
  justify-content: space-between;
  //   flex-direction: column;
  margin-bottom: 42px;
}

.ekonomik-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 0;
}

.ekonomik-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
  }

  &.ekonomik-subtitle-time .ant-input-number::after {
    content: "år";
  }

  & .ant-input-number {
    border: none;
    width: 120px;

    &::after {
      content: "kr";
      position: absolute;
      top: 0px;
      right: 22px;
    }
  }

  & .ant-input-number-disabled {
    background-color: transparent;
  }

  & .ant-input-number-input {
    text-align: right;
    padding-right: 38px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #626363;
  }
}

.ekonomik-lable {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #626363;

  &+.ant-slider {
    margin: 32px 6px 51px;
  }

  &+div .ant-slider-track,
  &+div .ant-slider-handle {
    background-color: #799AA9;
    border: solid 2px #799AA9;
  }
}

.ekonomik-comment {
  margin-top: 42px;
}

.ant-switch-checked {
  background-color: #799AA9;
}