body.light-theme {
    --border-color: #E6E6E6;
    --border-color-active: #808080;
    --border-color-hover: #FB7B34;

    --label-color: #0F0F0F;
}

body.dark-theme {
    --border-color: #2B2726;
    --border-color-active: #5B5958;
    --border-color-hover: #5B5958;

    --label-color: #555353;
}

.twofactor-wrapper {
    width: 360px;
    margin: 0 auto;
}

.twofactor-header {
    font-family: Aeonik Quant;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: center;
    color: #0F0F0F;
    margin-bottom: 24px;
}

.twofactor-code-img-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.code-title-text {
    text-align: left;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: #0F0F0F;
    padding-left: 24px;
    margin: 0;
}

.code-btn {
    width: 100%;
    margin-top: 24px;
    height: 40px;
    border-radius: 24px;
    text-align: center;
    color: #FCFCFC;
    background-color: #0F0F0F;

    &:hover,
    &:focus,
    &.ant-input-number-focused,
    &:active {
        box-shadow: none;
    }
}

.twofactor-code-footer {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    text-align: center;
    color: #0F0F0F;
    margin-top: 32px;
}

.login-form {
    .code-title-text {
        color: var(--label-color);
    }

    input.custom-input {
        width: 360px;
        height: 48px;
        border-radius: 24px;
        padding: 0 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        border: 1px solid var(--border-color);
        background-color: var(--bg-primary-color);
        color: var(--fc-secondary);

        &:hover {
            border: 1px solid var(--border-color-hover);

        }

        &:active {
            border: 1px solid var(--border-color-active);

        }

        & .ant-input-number-input-wrap {
            margin-top: 10px;

            &>input {
                padding-left: 0;
            }
        }

        &::placeholder {
            color: var(--login-placeholder-fc);
        }
    }
}