.confirm-reg-wrapper {
    background-image: url('../Verbier_BackSide.jpg');
    background-position: 0 -200px;
    background-repeat: no-repeat;
    background-size: 100%;
    height: calc(100vh - 91px - 80px);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-reg-container {
    background-color: white;
    width: 500px;
    margin: 200px 0;
    padding: 40px 40px 40px 40px;
    display: flex;
    flex-direction: column;

    p {
        text-align: center;
    }

    .confirm-reg-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;

        a {
            color: dodgerblue;
        }
    }
}

.sign-up-confirm-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.mail-pic-wrapper {
    width: 100px;
    display: flex;
    justify-content: center;
}

.mail-text-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mail-text {
    max-width: 360px;
    display: flex;
    justify-content: center;
    margin: 0;
    text-align: center;
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    color: #4D4D4D;
}

.mail-change-link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #0F0F0F;
    margin-bottom: 24.62px;
}