.sider-style {
    text-align: center;
    color: #fff;
    border-right: 1px solid #E1E1E9;

}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    max-height: 71px;
}

.logotype {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 12;

    img {
        height: auto;
        width: 146px;
    }
}

.client-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 16px 16px;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);

    & .logout-link {
        width: 100%;
        color: #DC180C;
        padding: 12px 56px;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
    }
}

.client-button-wrapper {
    padding-top: 10px;
    max-height: 48vh;
    min-height: 48vh;
    overflow-y: auto;
    background-color: #FCFCFC;
    transition: max-height 1s;

    &.hidden {
        max-height: 0;
        min-height: 0;
        padding-top: 0;
        overflow: hidden;
    }
}

.client-nav-wrapper {
    padding-top: 10px;
    max-height: 62vh;
    overflow-y: auto;

    &.hidden {
        max-height: 0;
        min-height: 0;
        padding-top: 0;
        overflow: hidden;
    }

    &>.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
        padding: 0;
        background-color: transparent;
        border-inline-end: none !important;

        & .ant-menu-submenu-title {
            padding-left: 14px;
            background-color: #F6F7F9;
        }

        & .ant-menu.ant-menu-sub.ant-menu-inline {
            background: none !important;

            &>.ant-menu-item.ant-menu-item-only-child {
                height: 48px;
            }
        }

        & span.ant-menu-title-content {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: #0F0F0F;
        }
    }

    .ant-menu-submenu-title {
        margin: 0 !important;
    }

}


.client-box {

    &-title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &.user-box {
            background: #F6F7F9;
            border-radius: 8px;

            &.selected-user {
                color: #0958d9;
                border-color: #0958d9;
                background: #ffffff;
            }
        }
    }

    &-text {
        font-size: 13px;
        font-weight: 500;
        line-height: 17.76px;
        letter-spacing: 0.02em;
        text-align: left;
        width: fit-content;
        margin: 0;
        padding: 0;
        color: #3C4043;

        &-wraper {
            box-sizing: border-box;
            width: 131.42px;
            text-align: left;
            padding-left: 10px;

            &>span.ant-typography {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }

        }
    }
}

.add-client-btn {
    width: calc(100% - 16px);
    height: 40px;
    background: #FCFCFC;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #0F0F0F;
    border-radius: 24px;
}

.client-box-title {
    width: 226px;
    margin-top: 16px;
    border: 1px solid #000000;
    border-radius: 24px;
    background-color: #FCFCFC;
    padding: 12px 16px;

    &.uncollapsed {
        border: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(.uncollapsed) {
        display: flex;
        align-items: center;
    }

    &.desabled {
        cursor: no-drop;
    }
}

.user-box {
    width: 226px;
    min-height: 64px;
    margin-top: 16px;
    border: 1px solid #000000;
    border-radius: 24px;
    background-color: #FCFCFC;
    padding: 12px 16px;
    transition: height 1s;

    &.uncollapsed {
        border: none;
    }

    &:not(.uncollapsed) {
        display: flex;
        align-items: center;
    }

    &-title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
}

.ant-btn.client-list-item {
    display: block;
    width: 100%;
    text-align: left;
    height: 44px;
    padding-left: 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &.selected-user {
        background: #ffffff;

        &>span {
            color: #0958d9;

        }
    }

}

.ant-btn.client-menu-btn {
    display: block;
    width: calc(100% - 16px);
    height: 44px;
    padding-left: 56px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;

    &.selected {
        border-radius: 24px;
        background: #FB7B34;
    }

}

li.ant-menu-submenu>.ant-menu.ant-menu-sub.ant-menu-inline {
    & .ant-menu-item {
        width: calc(100% - 16px);
        height: 44px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #0F0F0F;
        margin: 0;

        &>.ant-menu-title-content {
            font-size: 14px;

        }

        &.ant-menu-item-selected {
            border-radius: 24px;
            background: #FB7B34;

            &>.ant-menu-title-content {
                font-size: 16px;

            }
        }
    }

}

div.ant-menu-submenu-title {
    &+ul.ant-menu.ant-menu-sub.ant-menu-inline {
        margin-top: 10px;
    }
}

.button-title-wrap {
    display: flex;
    flex-wrap: wrap;

    &>p {
        margin: 0;
        width: 100%;
    }
}