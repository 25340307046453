.instrument-tbd-wrapper {
  width: 100%;

  .tabs-position {
    z-index: 20;
    background-color: white;
    width: 100vw;
    position: fixed;
    top: 87px;
    left: 0;

    .tabs-row {
      background-color: white;
      padding: 10px 20px;
      border-bottom: 1px solid black;

      a+a {
        margin-left: 30px;
      }

      a {
        color: black;
      }

      .active-link {
        color: #217fe4;
      }
    }
  }
}

.cell-button-wrapper {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  gap: 24px;
  align-items: center;
  min-height: 20px;

  & button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

.maping-error {
  font-size: 12px;
  color: red;
}

.maping-error-text {
  font-size: 12px;
  color: rgb(0 0 0 / 85%);
}

.maping-helper {
  font-size: 14px;
  font-weight: 600;
  color: rgb(0 0 0 / 85%);
  margin-bottom: 5px;
}