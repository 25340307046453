.out-container-title.list-title {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 24px;
}

.maps-tabs-wrapper {
    flex-wrap: nowrap;
    margin: 0 0 48px;
    padding: 0;
    gap: 24px;
}

.map-name-wrapper {
    min-width: 1128px;
    display: flex;
    flex-wrap: nowrap;
    gap: 48px;
    padding: 0 24px;
}

.map-name-column {}

.map-description-column {
    width: 100%;
    max-width: 672px;

}

.select-all-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.custom-select-wrapper {
    padding: 0 24px 24px;

    & .ant-select-item.ant-select-item-option.ant-select-item-option-active {
        border-color: rgb(252, 182, 97);

    }

    & .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
        border-color: #FB7B34;

        &>div.ant-select-item-option-content {}
    }
}