body.dark-theme {
    --header-color: #898888;
    --title-color: #656464;
    --text-color: #FCFCFC;
}

body.light-theme {
    --header-color: #0F0F0F;
    --title-color: #0F0F0F;
    --text-color: #0F0F0F;
}

.client.baner-wrapper {
    padding: 0 24px;
    max-width: 1440px;
}

.support-baner {
    width: 100%;
    height: auto;
}

.suport-text-wrapper {
    height: 197px;
    display: flex;
    gap: 42px;
    margin-top: 56px;

    &>.suport-text-block-left {
        flex-basis: 50%;

        &>h2 {
            color: var(--header-color);
            text-align: right;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.4px;
            margin: 0;

        }
    }

    &>.suport-text-block-right {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    & .support-label {
        color: var(--title-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
        margin-bottom: 24px;

        &+a.support-text {
            color: var(--text-color);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;

            &:visited,
            &:active {
                color: var(--text-color);

            }
        }
    }
}