.divider-label {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    margin: 12px 0 0 0;

}

div.goal-divider {
    margin: 0 0 12px;
}

.goal-item {
    &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    &-input {
        padding: 4px 10px;
    }

    &-input-wrapper {
        box-sizing: border-box;
        width: 120px;
    }

    &-firstinput-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
    }

    &-button,
    &-button:hover,
    &-button:focus {
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        border: none !important;
        box-shadow: none !important;
        margin-right: 4px;
    }

    &-input-label {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #626363;
        margin: 0;

    }

}

.goal {
    &-addbutton {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 3px;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #305572;
        border: none;
        box-shadow: none;

        &-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        &:active,
        &:hover,
        &:focus {
            color: #305572;
            box-shadow: 0 2px 0 rgba(63, 150, 159, 0.3);
        }
    }

}

.prefix-currence {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #3F969F;
}