.forgot-password-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F7F9;
}

.forgot-password-form {
    background-color: var(--bg-secondary-color);
    width: 400px;
    padding: 40px !important;
    display: flex;
    flex-direction: column;
}

.forgot-password-container {
    display: flex;
    flex-direction: column;
    position: relative;

    label::before {
        content: none !important;
    }
}

#forgot-password-form {
    width: 400px;
    padding: 16px;
}

.reset-pawss-back {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--bankId-header-color);
    opacity: var(--bankId-header-opacity, 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.divider-password {
    background: linear-gradient(90deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);

}

.reset-pawss-sing-wrapp {
    font-weight: 400;
}

.reset-pawss-signup {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--fc-secondary);
    margin-left: 8px;
}

.pass-setup-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    color: var(--fc-secondary);
    text-align: center;
    margin: 49px 0 32px;
}