.sign-up-row {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F7F9;
}

#reset.sign-up-container {
    width: 100%;
    max-width: 550px;
    padding: 20px 40px !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    box-sizing: border-box;

    .sign-up-input-block {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 10px;
    }

    a {
        color: dodgerblue;
        display: block;
        text-decoration: underline;
        margin-bottom: 5px;
    }

    ul {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .agreement-block {
        display: flex;
        align-items: center;
        flex-direction: row !important;

        input {
            margin-right: 10px;
        }
    }
}

.sign-up-wrapper {
    display: flex;
    flex-direction: column;

    label::before {
        content: none !important;
    }
}

.checkbox-show-password-block {
    width: fit-content;
    margin-bottom: 15px;
}

.radio-input-block {
    display: flex !important;
    justify-content: space-around;
    padding: 0 20px;
    margin-bottom: 30px !important;
}

.sign-up-form-wrapper {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sign-up-form {
    padding: 0;
    width: 360px;
}

.sign-up-form-check-wrap {
    padding: 0 24px;
}

.sign-up-check-lable {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
    margin: 0;
}

.signup-submit-btn {
    height: 40px;
    width: 360px;
    border-radius: 24px;
    background-color: #0F0F0F;

    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #FCFCFC;

    &:disabled {
        background-color: #CCCCCC;
        color: #525252;
    }

    &:hover {
        border: 1px solid #FB7B34;
    }

}