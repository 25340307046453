.tabs-position {
  z-index: 20;
  background-color: white;
  width: 100vw;
  position: fixed;
  top: 81px;
  left: 0;
}

.form-header {
  display: flex;
  justify-content: space-between;
}

.form-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.button-container {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.main-button.my-profile-button {
  width: 480px;
  padding-left: 46px;
  padding-right: 23px;
  margin-bottom: 24px;
}

.btn-text {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}

.form-phone {
  margin-bottom: 6px;
  width: 100%;
  position: relative;
}

.form-body {
  padding-top: 48px;
  margin-right: 0;
  margin-left: 0;
}

.phone-del-button {
  border: none;
  margin-top: 28px;
}

.phone-input-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: flex-start;
}

.client-name {
  font-size: 16px;
  font-style: italic;
  color: red;
}

.profile-wrap {
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F6F7F9;
}

.profile-inner-wrapper {
  padding: 48px 24px;
}

.profile-data-row {
  gap: 48px;
  padding: 0 !important;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  &.phone-row {
    max-width: 1128px;
  }
}

.profile-data-addbutton {
  height: 40px;
  padding: 12px 24px;
  margin-top: 24px;
  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
}

#client-profile {
  & .ant-form-item {
    margin-bottom: 0;
  }
}

.profile-delete-btn {
  position: absolute;
  top: 33px;
  right: 17px;
  cursor: pointer;
}

.pass-setup-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  color: var(--fc-secondary);
  text-align: center;
  margin: 49px 0 32px;
}

.pass-setup-term-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
  color: var(--fc-secondary);
}

svg.terms-link {
  cursor: pointer;

  &>g>path {
    fill: var(--login-forgot-arrow, #0F0F0F)
  }
}

.reset-pass-input {
  align-items: center;

  & .ant-form-item-explain.ant-form-item-explain-connected {
    max-width: 360px;
  }
}

.reset-pass-agreement {
  & span {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--fc-secondary);
  }
}

.confirm-success-wrapper {
  & svg {

    &>g>path {
      fill: var(--fc-secondary);
    }

    &>g>circle {
      fill: transparent;
    }
  }
}