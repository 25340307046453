.optimisation-chart-container {
  width: 100%;
  height: 100%;
  // max-width: 970px;

  & .ant-skeleton.ant-skeleton-element {
    width: 100%;
    min-height: '400px'
  }

}

.opt-charts-wrapper {
  & .ant-skeleton.ant-skeleton-element {
    width: 100%;
    min-height: '400px'
  }
}

.optimisation {
  &-optdata {
    padding: 32px 24px;
    flex-direction: column;
    background-color: #FAE5D8;
    flex-basis: 50%;

    &-text {
      font-family: Aeonik Quant;
      font-size: 20px;
      font-weight: 500;
      line-height: 23.2px;
      color: #0F0F0F;
      margin: 0;
      margin-bottom: 40px;
    }

    &-card {
      width: 100%;
      padding: 16px 12px;
      border-radius: 8px;
      border: 1px solid #E6E6E6;
      background-color: #fff;
      margin-bottom: 13px;

      &-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6.5px;
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 400;

      }

      &-data-wrap {
        margin: 16px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

      }

      &-data {
        padding-left: 6.5px;
        font-family: Aeonik Quant;
        font-size: 28px;
        font-weight: 400;
        line-height: 32.48px;
        letter-spacing: 0.04em;
        color: #242424;
        margin: 0;
      }

      &-dig {
        padding: 2px 8px;
        height: 24px;
        border-radius: 8px;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #6367C3;
        background-color: #6367C31F;
        margin: 0;

        &.red {
          color: #DC180C;
          background-color: #DC180C1F;
        }

        &.green {
          color: #139E19;
          background-color: #139E191F;
        }

        &.blue {
          color: #6367C3;
          background-color: #6367C31F;
        }
      }
    }
  }

  &-currentdata {
    padding: 32px 24px;
    flex-direction: column;
    background-color: #fff;
    flex-basis: 50%;
  }


}

.optimisation-table-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.optimisation-chart-container-data-text {
  font-weight: 600;
  font-size: 16px;
  font-family: 'Lato';
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 48px;

  & .marker {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background-color: #C94709;
    display: inline-block;
    margin-right: 12px;

    &.optimal {
      background-color: #305572;
    }
  }
}

.optimisation-chart-container-data-items {
  display: flex;
  position: fixed;
  gap: 13px;
  top: 15px;
  right: calc(50% - 210px);
  z-index: 1301;

  & .optim-top-info-item {
    display: flex;
    align-items: center;
    flex-basis: 33.333%;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    padding: 8px 12px;
    gap: 20px;

    &-title {
      font-family: Aeonik Quant;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.24px;
      color: #4D4D4D;
      margin-bottom: 4px;
      white-space: nowrap;
      margin: 0;
    }

    &-value {
      display: inline-block;
      font-family: Aeonik Quant;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 8px;
      white-space: nowrap;
      padding: 2px 8px;

      &.green {
        background: #139E191F;
        color: #139E19;
      }

      &.red {
        background: #DC180C1F;
        color: #DC180C;

      }

      &.blue {
        background: #6367C31F;
        color: #6367C3;

      }
    }
  }


}



.optimisation-chart-container-slider {
  .ant-slider-handle {
    background-color: #305572;
    border-color: #fff;
    box-shadow: 0px 0px 4px rgba(13, 13, 43, 0.05), 0px 5px 10px rgba(13, 13, 43, 0.1);

    &:hover {
      background-color: #305572;
    }
  }

  .ant-slider-track {
    background-color: #305572;

    &:hover {
      background-color: #305572;
    }
  }

  .ant-slider-rail {
    background-color: #DFDFDF;
  }

  &.ant-slider {
    &:hover {
      .ant-slider-track {
        background-color: #305572;
      }

      .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: #fff;
        box-shadow: 0px 0px 4px rgba(13, 13, 43, 0.05), 0px 5px 10px rgba(13, 13, 43, 0.1);
      }
    }
  }
}

.optimisation-table-wrapper>.loading-wrapper.gear {
  height: 350px;
}

.portfolio-container>.loading-wrapper.gear {
  height: 300px;
}

.highcharts-point.highcharts-point-select {
  fill: #FB7B34;
}

#pdf-part-1 {
  width: 100%;
}

.ant-slider.optimisation-chart-container-slider {
  &>.ant-slider-handle {

    &:focus::after,
    &::after {
      background-color: #305572;
    }
  }
}

.optim-change-btn {
  padding: 8px 20px;
  height: 38px;
  border: 1px solid #000000;
  color: #000000;
}

.btn-edit-save.optim-continue-btn {
  min-width: 207px;
  border-radius: 24px;
  height: 40px;
}

.optim-top-info {
  display: flex;
  position: fixed;
  gap: 30px;
  top: 11.5px;
  right: calc(50% - 140px);
  z-index: 1301;

  &>.optim-top-info-item {
    display: flex;
    flex-direction: column;
    width: 33.333%;

    &>.optim-top-info-item-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16.39px;
      text-align: left;
      margin-bottom: 4px;
      white-space: nowrap;
    }

    &>.optim-top-info-item-value {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.12px;
      letter-spacing: 0.20000000298023224px;
      text-align: left;

      width: 91px;
      height: 27.01px;
      padding: 3px 16px 3px 16px;
      gap: 10px;
      border-radius: 3px 0px 0px 0px;
      background: #1AC39133;
      white-space: nowrap;
    }
  }

}

.opt-inner-wrapper {
  padding: 0 24px;
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;

  &>.opt-chart-wrap {
    flex-basis: 70%;
  }

  &>.opt-data-wrap {
    flex-basis: 30%;
    display: flex;
    align-items: stretch;

  }
}

.opt-charts-wrapper {
  padding: 24px;
}

.opt-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.asset-allocation-title {
  font-family: Aeonik Quant;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #0F0F0F;
  text-align: center;
}

.donut-wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.donut-data-chart {
  flex-basis: 30%;
}

.donut-data {
  flex-basis: 65%;
}

.donut-chart-wrapper {
  width: 300px;
  height: 300px;
}

.donut-data-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 370px;
  margin-bottom: 24px;
}

.donut-category-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.donut-category-legend {
  width: 14px;
  height: 14px;
  border-radius: 4px;

}

.donut-category-name {
  font-family: Aeonik Quant;
  font-size: 17px;
  font-weight: 500;
  line-height: 16.24px;
  color: #0F0F0F;
  margin: 0;
}

.donut-category-persent {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 16.24px;
  letter-spacing: 0.04em;
  color: #4D4D4D;
  margin: 0;
}

.opt-paper-title {
  font-family: Aeonik Quant;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  color: #0F0F0F;
  margin: 0;
}

.toolpit-wrapper {
  padding: 2px 9px 0;
  min-width: 169px;
  white-space: nowrap;
}

.toolpit-title {
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  color: #808080;
  margin-bottom: 12px;
}

.toolpit-point {
  font-family: Aeonik Quant;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #0F0F0F;
}

.linebasic-tooltip-positive {
  display: inline-block;
  height: 20px;
  background-color: #139E191F;
  border-radius: 8px;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  letter-spacing: 0.04em;
  color: #139E19;
  padding: 3px 8px;
  margin-bottom: 8px;
}

.linebasic-tooltip-negative {
  display: inline-block;
  height: 20px;
  background-color: #DC180C1F;
  border-radius: 8px;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  letter-spacing: 0.04em;
  color: #DC180C;
  padding: 3px 8px;
  margin-bottom: 8px;
}

.back-to-opt {
  margin-bottom: 20px;
}

.sugg-portfolio tr>th,
#pdf-part-6 tr>th.ant-table-cell,
#pdf-part-7 tr>th.ant-table-cell {
  font-size: 18px;
}

.sugg-portfolio tr>td,
#pdf-part-6 tr>td,
#pdf-part-7 tr>td {
  font-size: 16px;
}

.sugg-portfolio .holdings-assetclass-cell-asset,
#pdf-part-6 .holdings-assetclass-cell-asset,
#pdf-part-7 .holdings-assetclass-cell-asset {
  font-size: 18px;
}

.sugg-portfolio .holdings-assetclass-cell-category,
#pdf-part-6 .holdings-assetclass-cell-category,
#pdf-part-7 .holdings-assetclass-cell-category {
  font-size: 16px;
}

.opt-user-name {
  font-family: Aeonik Quant;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #FB7B34;
}