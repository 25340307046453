.history-wrapper {
  width: 100%;
  padding: 20px;
}
.portfolio-history-wrapper {
  .tabs-position {
    z-index: 20;
    background-color: white;
    width: 100vw;
    position: fixed;
    top: 87px;
    left: 0;
  }
}
