body.dark-theme {
    --bg-root-color: #000000;
    --bg-primary-color: #131111;
    --bg-secondary-color: #1B1918;

    --fc-contrast: #FFFFFF;
    --fc-primary: #656464;
    --fc-secondary: #F2EEED;
    --fc-prim-color: #FB7B34;
    --fc-third-color: #827f7f;

    --logo-img-border-radius: 26px;
    --logo-img-opacity: 50%;

    --login-wrapper-padding: 18.5px 19.33px 19.4px;
    --login-btn-color: #131111;
    --login-btn-bgc: #FB7B33;
    --login-btn-bgc-hover: #FF9F53;
    --login-btn-border-hover: #5B5958;
    --login-label-fc: #555353;
    --login-placeholder-fc: #514E4D;
    --login-switch-border-color: #808080;
    // --login-switch-bgc: #FB7B34;
    --login-switch-handle-border: #FB7B34;
    --login-forgot-btn-border: 1px solid #808080;
    --login-forgot-btn-br: 24px;
    --login-forgot-arrow: #FB7B34;

    --bankId-header-color: #FCFCFC;
    --bankId-header-opacity: 50%;

    --opacity-25: 25%;

    --border-color: #2B2726;
    --border-hover-color: #423A38;

}

body.light-theme {
    --bg-root-color: #ffffff;
    --bg-primary-color: #ffffff;
    --bg-secondary-color: #ffffff;

    --fc-contrast: #000000;
    --fc-primary: #656464;
    --fc-secondary: #0F0F0F;
    --fc-prim-color: #FB7B34;
    --fc-third-color: #656464;

    --logo-img-border-radius: none;
    --logo-img-opacity: 100%;

    --login-wrapper-padding: 0;
    --login-btn-color: #FCFCFC;
    --login-btn-bgc: #0F0F0F;
    --login-btn-bgc-hover: #0F0F0F;
    --login-btn-border-hover: #FB7B34;
    --login-label-fc: #0F0F0F;
    --login-placeholder-fc: #4D4D4D;
    // --login-switch-border-color: #808080;
    --login-switch-bgc: #FB7B34;
    --login-switch-handle-border: #000000;
    // --login-forgot-btn-border: 1px solid #808080;
    --login-forgot-btn-br: 24px;
    // --login-forgot-arrow: #FB7B34;

    --bankId-header-color: #0F0F0F;
    --bankId-header-opacity: 100%;

    --border-color: #2B2726;
    --border-hover-color: #FB7B34;

}