.select-container {
  width: 480px;
  height: 171px;
  padding: 60px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 40px;
}

.main-button {
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #305572;
  font-size: 18px;
  border: none;
  cursor: pointer;
  color: white;
  padding: 10px 15px;
  width: 100%;
  transition: 0.2s;
  outline: none;
  border-radius: 20px;
  &_innet {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
.main-button:active {
  transition: 0.2s;
  transform: scale(1.05);
}

.disabled {
  background-color: lightgrey !important;
  color: grey !important;
}
