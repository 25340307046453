/* Основные настройки для кругов */
circle {
  transition: stroke-opacity 0.5s ease-in-out;
}

/* Анимация для первого круга */
@keyframes opacityPulse1 {

  0% {
    stroke-opacity: 0.15;
  }

  50% {
    stroke-opacity: 0.5;
  }

  100% {
    stroke-opacity: 0.3;
  }
}

.circle1 {
  animation: opacityPulse1 1.5s infinite;
}

/* Анимация для второго круга */
@keyframes opacityPulse2 {

  0% {
    stroke-opacity: 0.3;
  }

  50% {
    stroke-opacity: 0.15;
  }

  100% {
    stroke-opacity: 0.5;
  }
}

.circle2 {
  animation: opacityPulse2 1.5s infinite;
}

/* Анимация для третьего круга */
@keyframes opacityPulse3 {

  0% {
    stroke-opacity: 0.5;
  }

  50% {
    stroke-opacity: 0.3;
  }

  100% {
    stroke-opacity: 0.15;
  }
}

.circle3 {
  animation: opacityPulse3 1.5s infinite;
}