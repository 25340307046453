.terms-conditions {
  &-header {
    font-family: 'Roboto';
    font-style: normal;
    max-width: 727px;
    text-align: center;
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    letter-spacing: 0.2px;
    margin-top: 72px;

    &-text {
      max-width: 749px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #000000;
      margin-top: 55px;
      margin-bottom: 55px;
    }

    &-subtext {
      text-align: left;

    }
  }

  &-container.detail-container {
    width: 749px;
  }


  &-title {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    margin-top: 11px;
    margin-bottom: 0;
  }

  &-field {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
    padding: 12px 10px;
    border: 1px solid #DCDDDD;
    border-radius: 5px;
  }

  &-order-list {
    width: 749px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-left: 19px;
    margin-bottom: 21px;

  }

  &-paragraph {
    width: 800px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
  }

  &-account-text {
    width: 523px;
  }

  &-fee {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      width: 80%;
    }

    &-header {
      width: 160px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.2px;
      color: #000000;
    }

    &-point {
      width: 160px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #626363;
      margin-right: 8px;
    }

    &-input {
      width: 110px;
      height: 25px;
      background: #FFFFFF;
      border: 1px solid #DCDDDD;
      border-radius: 5px;
    }

    &-row {
      margin-bottom: 16px;
    }

    &-suffix {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #626363;
    }
  }

  &-link {
    margin-left: 8px;
    margin-right: 8px;
  }

  &-button {
    width: 418px;
    height: 40px;

    &-wrapper {
      margin-top: 52px;

    }
  }
}


.account-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.account-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-column-gap: 16px;
  column-gap: 16px;
  justify-content: flex-start;
  align-items: center;
}

.account-select-wrapper {
  box-sizing: border-box;
  margin-right: 115px;
  min-width: 192px;

  & .ant-select {
    width: 100%;

    & span {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #626363;
    }
  }
}

.terms-conditions-button:disabled {
  background-color: #626363;
  cursor: no-drop;
}

.terms-alert {
  margin-top: 16px;
}

// ---------------------------------------------------------------
.detail-container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 480px;
  padding: 20px;
  margin-bottom: 14px;
}

.detail-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.input-text {
  margin-left: 10px;
}

.account-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #626363;
  margin: 14px 0;
}

.btn-container {
  width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  margin-top: 46px;
}

.menu-title {
  width: 100%;
  text-align: center;
  margin: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #626363;
  margin-top: 4px;
}

.menu-wrapper {
  box-sizing: border-box;
  padding: 0;
  width: 81px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.radio-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #626363;
}

.radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.myDetails-addbutton {
  margin-top: 6px;
  height: 20px
}

.myDetails-phone {
  margin-bottom: 0;
  width: 100%;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #799AA9;
}


.my-details-checkbox.experience-background-checkbox .ant-checkbox-inner {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #799AA9;
  border-radius: 3px;
}

.my-details-checkbox.experience-background-checkbox>.ant-checkbox-checked>.ant-checkbox-inner::after {
  left: 28.6%;
  width: 7.2px;
  height: 7.5px;
}

.my-detail-checkbox-wrapper {
  margin-bottom: 0;
}