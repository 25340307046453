.advisor-wrapper {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.wait-activated-status {
  color: rgba(0, 0, 0, 0.3);
}

.submit-form-wrapper {
  margin-top: 70px;
}

.disabled-cell {
  opacity: 0.3;
}

.advisor-container {
  padding: 10px 24px;
  margin-top: 24px;
}

.status-advisor {
  display: inline-block;
  border-radius: 8px;
  padding: 3px 18px;
  height: 24px;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;


  &.yellow {
    color: #E59A00;
    background-color: #FFAB001F;
  }

  &.green {
    color: #139E19;
    background-color: #139E191F;
  }

  &.red {
    color: #DC180C;
    background-color: #DC180C1F;
  }

  &.purple {
    color: #6367C3;
    background-color: #6367C31F;
  }
}

button.action-btn {
  width: 107px;
  height: 40px;
  padding: 0px 32px;
  border-radius: 24px;
  border: 1px solid #0F0F0F;
  display: flex;
  justify-content: center;
  align-items: center;
  &.advisor-action-btn {
    width: 107px;
    height: 40px;

  }

}

.filter-counter {
  display: flex;
  align-items: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FB7B34;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  color: #0F0F0F;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0 4px;
  padding-top: 2px;
}

.filter-btn {
  padding: 6px 24px;
  height: 36px;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  background-color: #FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;

  &>span {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

  }
}

.modal-advisor-text {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: center;
  color: #262626;
  margin: 0;
}

.modal-advisor-name {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  color: #262626;
  text-align: center;
  margin: 0;
  margin-top: 36px;
}

.modal-advisor-btn-wrapper {
  justify-content: center;
  gap: 16px;
  margin-top: 50px;
  margin-bottom: 52px;
}
.modal-action-btn.delete-btn {
  background-color: #DC180C;
  color: #FCFCFC;
}

.ant-pagination-options-size-changer .rc-virtual-list-holder-inner {
  background-color: #FCFCFC;
}