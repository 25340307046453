.legal {
    &-header {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.2px;
        color: #000000;
    }

    &-lable {
        margin: 12px 0 0 0;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #626363;
    }
}