.customer {
    &-radio {
        margin-bottom: 0;
        padding-bottom: 12px;
    }

    &-subtitle {
        margin-top: 12px;
    }

    &-lable {
        margin: 12px 0 0 0;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #626363;
    }

    &-where-money {
        display: grid;
        grid-template-columns: 45% 45%;

        & label.ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            margin-left: 0;
            margin-bottom: 12px;

            &>.ant-checkbox-checked .ant-checkbox-inner {
                background-color: #799AA9;
                border-color: #799AA9;
            }
        }


        & span {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: #626363;
        }
    }
}

.missing-checkbox-wrapper {
    margin-top: 12px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    & .risk-profile-lable {
        margin-top: 0;
    }
}

.percentage-votes.ant-input-number::after {
    content: "%";
    position: absolute;
    top: 0px;
    right: 22px;
}

.warning-text {
    padding: 10px 5px;
    background-color: #799aa973;
    color: red;
    font-weight: 500;
    font-style: italic;
    text-align: center;
}