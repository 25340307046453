.saving {
    &-header {
        margin-bottom: 12px;
    }

    &-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #626363;
        margin-bottom: 16px;
    }

    &-radio {
        margin-bottom: 24px;

        & span.ant-radio {
            border: 1px solid #799AA9;
            border-radius: 50%;

            &-checked .ant-radio-inner {
                border-color: #799AA9;

                &::after {
                    background-color: #799AA9;
                }
            }

            &-checked::after {
                border: 1px solid #799AA9;
            }

            &:hover {
                border-color: #799AA9;
            }
        }
    }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #799AA9;

}

.saving-lable {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    margin: 0;
}

.saving-subtitle-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0;

    & span {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #626363;
    }

    & .ant-input-number {
        border: none;
        width: 120px;
    }

    & .ant-input-number-disabled {
        background-color: transparent;
    }

    & .ant-input-number-input {
        text-align: right;
        padding-right: 48px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #626363;
    }
}

.saving-lable {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;

    &+.ant-slider {
        margin: 32px 6px 51px;
    }

    &+div .ant-slider-track,
    &+div .ant-slider-handle {
        background-color: #799AA9;
        border: solid 2px #799AA9;
    }
}

.saving-range-wrapper {
    position: relative;
}

.saving-range-suffix {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #626363;
    position: absolute;
    top: 4px;
    right: 18px;
}

.saving-input {
    width: 440px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DCDDDD;
    border-radius: 5px;
}