body.dark-theme {
    --content-row-bcg: #131111;
}

body.light-theme {
    --content-row-bcg: #FCFDFD;
}

.layout-auth {
    display: flex !important;
    min-height: 100vh !important;
    flex-direction: column;
    background-color: var(--bg-root-color);
}

.main-container {
    min-height: 100vh;
    padding: 10px;
}

.content {
    flex: 1;
    background: white;
    display: flex;
}

.left-label {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10;
    min-height: 100vh;
    width: 76px;
    box-sizing: border-box;

    p {
        position: fixed;
        font-size: 25px;
        top: 150px;
        left: 60px;
        z-index: 10;
        transform: rotate(90deg);
        transform-origin: top left;
        margin: 0;
        padding: 0;
    }
}

.main {
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-row {
    min-height: 10vh;
}

.content-row {
    background-color: #FCFDFD;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    min-height: calc(100vh);
    line-height: 20px;
}

.client.content-row {
    background-color: var(--content-row-bcg);
    max-height: 100vh;
    overflow-y: auto;
}

.layout-main {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}