.forbiden-wrapper {

    min-height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forbiden-title {
    font-family: Aeonik Quant;
    font-size: 30px;
    font-weight: 700;
    line-height: 34.8px;
    text-align: left;
    color: #0F0F0F;
    margin-bottom: 48px;
}

.forbiden-btn {
    background-color: #FB7B34;
}